import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Icon } from '@gumtree/ui-library';
import { gutterSizes, colors } from '@gumtree/ui-library/src/base/theme';
import { removeItemsViaRegex } from '@gumtree/ui-library/src/utils/local-storage-service';
import { trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthBookFeature } from '@gumtree/shared/src/model/experiment';
import type { ShellState } from '../reducers/common';
import type { UsersData } from '../reducers/common/user-data';
import { postData } from '../login/post-data';
import logger from '../logger';

const regex = /^review-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export default function LogoutForm(props: { mobile?: boolean }) {
    const logoutToBff = useFeatureIsOn(GrowthBookFeature.LOGOUT_TO_BFF);
    const [_isLoading, setIsLoading] = useState(false);
    const state = useSelector(
        ({
            baseConfig: { sellerUrl },
            userData: { csrfToken },
        }: ShellState & { userData: UsersData }) => ({
            sellerUrl,
            csrfToken,
        }),
        () => true
    );

    const handleLogout = async () => {
        trackGA4PreNav<GA4.UserLogout>({ event: 'user_logout' });
        removeItemsViaRegex(regex);
    };

    const handleLogoutSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = {
            token: state.csrfToken
        };

        postData(formData, setIsLoading, '/bff-api/logout/via-form')
            .then(async (response) => {
                if (response?.status === 200) {
                    window.location.href = response?.redirectTo;
                }
            })
            .catch((e) => {
                logger.error(e);
            });
    };

    return (
        <form method="post" {...logoutToBff ? { onSubmit: handleLogoutSubmit } : {action: `${state.sellerUrl}/logout`}} data-testid="logout-form">
            {!logoutToBff && <input type="hidden" name="token" value={state.csrfToken} />}
            <LogoutSubmitButton type="submit" mobile={props.mobile} onClick={handleLogout}>
                <span>Logout</span>
                <Icon type="chevron-r" />
            </LogoutSubmitButton>
        </form>
    );
}

const LogoutSubmitButton = styled.button<{ mobile?: boolean }>`
    all: unset;

    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${gutterSizes.medium} 0;
    color: ${colors.white};
    cursor: pointer;

    span {
        margin-left: ${(props) => (props.mobile ? gutterSizes.xlarge : gutterSizes.medium)};
        &.icon {
            margin-right: ${(props) => (props.mobile ? gutterSizes.xlarge : gutterSizes.medium)};
        }
    }
`;
