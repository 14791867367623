import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Option } from '@gumtree/ui-library/src/form/type-ahead/type-ahead-options';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';

import type { ShellState, ShellUseDispatch } from '../../../reducers/common';
import * as searchBarActions from '../search-bar-actions';
import { ActiveField } from './types';

export const handleClickOnOption = ({
    dispatch,
    currentActiveField,
    option,
    useRecentHistory,
}: {
    dispatch: ShellUseDispatch;
    currentActiveField: ActiveField;
    option: {
        name: string;
        highlight?: string;
        category?: string;
        categoryDisplayName?: string;
    };
    useRecentHistory: boolean;
}) => {
    if (currentActiveField === ActiveField.KEYWORD) {
        dispatch(searchBarActions.selectKeywordSuggestion(option, useRecentHistory));
    } else {
        dispatch(searchBarActions.selectLocationSuggestion(option, useRecentHistory));
    }
};

export const getTypeAheadIcon = ({
    currentActiveField,
    keywordUseRecentHistory,
    locationUseRecentHistory,
}: {
    currentActiveField: ActiveField;
    keywordUseRecentHistory: boolean;
    locationUseRecentHistory: boolean;
}) => {
    if (currentActiveField === ActiveField.KEYWORD) {
        return keywordUseRecentHistory ? 'clock' : 'magnifying-glass';
    } else {
        return locationUseRecentHistory ? 'clock' : 'beacon';
    }
};

export const trackResultsShownEvents = ({
    isOpen,
    currentActiveField,
    keywordSuggestions,
    keywordUseRecentHistory,
    locationSuggestions,
    locationUseRecentHistory,
    hasTrackedKeywordHistoryShown,
    setHasTrackedKeywordHistoryShown,
    hasTrackedLocationHistoryShown,
    setHasTrackedLocationHistoryShown,
}) => {
    if (!isOpen) {
        return;
    }

    if (
        currentActiveField === ActiveField.KEYWORD &&
        keywordSuggestions?.length > 0 &&
        keywordUseRecentHistory &&
        !hasTrackedKeywordHistoryShown
    ) {
        trackV2('KeywordHistoryShown');
        trackGA4Event<GA4.RecentSearchHistoryShown>({ event: 'recent_search_history_shown' });
        setHasTrackedKeywordHistoryShown(true);
    } else if (
        currentActiveField === ActiveField.LOCATION &&
        locationSuggestions.length > 0 &&
        locationUseRecentHistory &&
        !hasTrackedLocationHistoryShown
    ) {
        trackV2('LocationHistoryShown');
        setHasTrackedLocationHistoryShown(true);
    }
};

export const useTypeAheadOptionsParams = ({
    currentActiveField,
    searchQuery,
    locationQuery,
    isOpen,
}) => {
    const dispatch = useDispatch() as ShellUseDispatch;
    const {
        keywordSuggestions,
        locationSuggestions,
        keywordUseRecentHistory,
        locationUseRecentHistory,
    } = useSelector((state: ShellState) => ({
        keywordSuggestions: state.searchBar?.keyword?.suggestions || [],
        locationSuggestions: state.searchBar?.location?.suggestions || [],
        keywordUseRecentHistory: state.searchBar?.keyword?.useRecentHistory,
        locationUseRecentHistory: state.searchBar?.location?.useRecentHistory,
    }));

    const [hasTrackedKeywordHistoryShown, setHasTrackedKeywordHistoryShown] = useState(false);
    const [hasTrackedLocationHistoryShown, setHasTrackedLocationHistoryShown] = useState(false);

    useEffect(() => {
        trackResultsShownEvents({
            isOpen,
            currentActiveField,
            keywordSuggestions,
            keywordUseRecentHistory,
            locationSuggestions,
            locationUseRecentHistory,
            hasTrackedKeywordHistoryShown,
            setHasTrackedKeywordHistoryShown,
            hasTrackedLocationHistoryShown,
            setHasTrackedLocationHistoryShown,
        });
    }, [currentActiveField, isOpen]);

    const isLimitReached =
        currentActiveField === ActiveField.KEYWORD
            ? searchQuery?.length >= 50
            : locationQuery?.length >= 50;
    const highlight = currentActiveField === ActiveField.KEYWORD ? searchQuery : locationQuery;
    const options =
        currentActiveField === ActiveField.KEYWORD ? keywordSuggestions : locationSuggestions;
    const useRecentHistory =
        currentActiveField === ActiveField.KEYWORD
            ? keywordUseRecentHistory
            : locationUseRecentHistory;

    const onOptionClick = (option: Option) => {
        handleClickOnOption({
            dispatch,
            currentActiveField,
            option,
            useRecentHistory,
        });
    };

    const removeSingleFromHistory = (text: string) => {
        if (currentActiveField === ActiveField.KEYWORD) {
            dispatch(searchBarActions.removeSingleSearchFromHistory(text));
        } else {
            dispatch(searchBarActions.removeSingleLocationFromHistory(text));
        }
    };

    const clearAllFromHistory = () => {
        if (currentActiveField === ActiveField.KEYWORD) {
            dispatch(searchBarActions.clearAllSearchesFromHistory());
        } else {
            dispatch(searchBarActions.clearAllLocationsFromHistory());
        }
    };

    const iconType = getTypeAheadIcon({
        currentActiveField,
        keywordUseRecentHistory,
        locationUseRecentHistory,
    });

    const titleOfRecentHistory =
        currentActiveField === ActiveField.KEYWORD ? 'Recent Searches' : 'Recent Locations';

    return {
        isLimitReached,
        highlight,
        isOpen,
        limit: 10,
        onOptionClick,
        options,
        useRecentHistory,
        removeSingleFromHistory,
        clearAllFromHistory,
        iconType,
        titleOfRecentHistory,
        showClearAllButton: true,
    };
};

export default useTypeAheadOptionsParams;
