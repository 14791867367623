import './framework/prebid/client/vendor/prebid';

import {
    WindowWithPbjs,
    WindowWithClientData,
    WindowWithDataLayer,
    WindowWithGa,
    WindowWithGoogleTag,
    WindowWithAdvertising,
    WindowWithAdSense,
} from '@gumtree/shared/src/types/window';
import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';
import {
    convertExperimentsToStringArray,
    convertExperimentValue,
} from '@gumtree/shared/src/util/experiments-service';
import { sanitiseToOnlyAlphanumeric } from '@gumtree/shared/src/util/string-sanitise';
import { getGrowthbookClientSide } from '@gumtree/shell/src/growthbook/init-growthbook';
import { GrowthBookFeature } from '@gumtree/shared/src/model/experiment';

import initLiveRamp from './framework/liveramp/init';
import dfpTrackBrowserType from './framework/dfp/browser-key-value-builder';
import dfpTrackScreenResolution from './framework/dfp/screen-resolution-key-value';
import dfpTrackTallMobile from './framework/dfp/tall-mobile-key-value';
import dfpTrackBelow360px from './framework/dfp/below-360px-key-value';
import dfpBffSrpResultsSize from './framework/dfp/results-size-key-value';
import loadDisplayAds from './framework/dfp/init-display-ads';
import startPrebid from './framework/prebid/start-prebid';
import trackAdsPerformance from './framework/track-ads-performance';
import getUrlParameter from './framework/util/extract-url-parameter';
import loadBingAds from './framework/bing/client-side/loader';
import initMicrosoftClarity from './framework/microsoft-clarity/init';
import { isAdSenseEnabled } from './framework/adsense/initialisation-util';
import { isBingEnabled } from './framework/bing/initialisation-util';
import { loadAdSenseAds } from './framework/adsense/load-ads';

const getPageData = (window: WindowWithClientData) => ({
    device: window.clientData.baseConfig.device.type!,
    experiments: window.clientData.experiments,
    pageType: window.clientData.baseConfig.pageType,
    breadcrumbs: 'breadcrumbs' in window.clientData ? window.clientData.breadcrumbs : undefined,
    googlePpcVisit: getUrlParameter(window.location.search, 'utm_medium') === 'cpc',
    criteoPpcVisit: getUrlParameter(window.location.search, 'utm_source') === 'Criteo',
    currentPage:
        'resultsPage' in window.clientData
            ? window.clientData.resultsPage.adsPagination.currentPage
            : undefined,
    numberOfResults:
        'resultsPage' in window.clientData
            ? window.clientData.resultsPage?.searchAds?.length
            : undefined,
    l1Category:
        'displayAds' in window.clientData
            ? window.clientData.displayAds?.config?.l1Category
            : undefined,
    l2Category:
        'displayAds' in window.clientData
            ? window.clientData.displayAds?.config?.l2Category
            : undefined,
});

const initDisplay = async (
    window: WindowWithGoogleTag &
        WindowWithDataLayer &
        WindowWithClientData &
        WindowWithGa &
        WindowWithAdvertising
) => {
    const { device, experiments, pageType, l1Category, l2Category } = getPageData(window);

    const hashedUserIdFromDataLayer =
        window.dataLayer &&
        window.dataLayer[0] &&
        window.dataLayer[0].d &&
        window.dataLayer[0].d.ck;

    const serverConfigs = {
        ...window.clientData.displayAds,
        ...window.clientData.displayAds?.config,
    };

    if (serverConfigs?.targeting?.ptg) {
        const labsExperiments = convertExperimentsToStringArray(experiments);
        const trackedGamExperiments = []; // Add GrowthBook experiment ID here - e.g. GTP-3078
        const growthBookExperiments = trackedGamExperiments.map(
            (experimentId) =>
                `${experimentId}-${convertExperimentValue(
                    window.growthbook.getFeatureValue(experimentId, false)
                )}`
        );
        serverConfigs.targeting.ptg = [...labsExperiments, ...growthBookExperiments];
    }

    await loadDisplayAds(
        window.googletag,
        window,
        serverConfigs,
        window.clientData.displayAds?.config?.resultsSize,
        l1Category,
        l2Category ? l2Category : null,
        pageType,
        device,
        hashedUserIdFromDataLayer
    );

    // post initialising display
    dfpTrackTallMobile(window.googletag);
    dfpTrackBelow360px(window.googletag);
    dfpBffSrpResultsSize(window.googletag, window, pageType);
    dfpTrackBrowserType(window.googletag);
    dfpTrackScreenResolution(window.googletag);

    trackAdsPerformance({ googletag: window.googletag, ga: window.ga, performance });
};

const initPrebid = async (window: WindowWithGoogleTag & WindowWithPbjs & WindowWithClientData) => {
    const { device, pageType, experiments } = getPageData(window);

    const l1Category = window.clientData.displayAds?.config?.l1Category;
    const l2Category = window.clientData.displayAds?.config?.l2Category;
    const l3Category = window.clientData.displayAds?.config?.targeting?.l3;

    const screenSize = getCurrentBreakpoint(window)?.tshirt;

    await startPrebid(
        window.googletag,
        pageType,
        device,
        screenSize,
        l1Category,
        l2Category,
        l3Category,
        '',
        convertExperimentsToStringArray(experiments),
        window.clientData.displayAds,
        window
    );
};

export const initBing = async (window: WindowWithClientData) => {
    const bingConfig = window.clientData?.bingAds;
    if (!bingConfig) {
        return;
    }
    const {
        device,
        pageType,
        l1Category,
        numberOfResults = 0,
        currentPage,
        googlePpcVisit,
        breadcrumbs,
    } = getPageData(window);

    const breadCrumbLeaf = breadcrumbs?.links?.length
        ? breadcrumbs.links[breadcrumbs.links.length - 1].name
        : '';
    const sanitisedBreadCrumbLeaf = sanitiseToOnlyAlphanumeric(breadCrumbLeaf);

    const keywords =
        pageType === 'ResultsBrowse' && sanitisedBreadCrumbLeaf
            ? sanitisedBreadCrumbLeaf
            : bingConfig.keywords;
    const clientIp = bingConfig.clientIp;

    const bingVsAdSenseMobileVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_MOBILE, '');

    const bingVsAdSenseDesktopVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_DESKTOP, '');

    await loadBingAds({
        device,
        keywords,
        clientIp,
        l1Category,
        googlePpcVisit,
        pageType,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        currentPage,
        numberOfResults,
        window,
    });
};

const initAdSenseAds = (window) => {
    const { device, pageType, l1Category, numberOfResults = 0, currentPage } = getPageData(window);

    const { isQA } = window.clientData?.baseConfig ?? {};

    const isProd = !isQA;
    const isAdSenseLive =
        isProd || (!!getGrowthbookClientSide() && getGrowthbookClientSide().isOn('ADSENSE_LIVE'));

    const bingVsAdSenseMobileVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_MOBILE, '');

    const bingVsAdSenseDesktopVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_DESKTOP, '');

    const query = (window as WindowWithClientData).clientData?.adsenseAds?.query;

    loadAdSenseAds({
        pageType,
        l1Category,
        currentPage,
        device,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        numberOfResults,
        isAdSenseLive,
        window,
        query,
    });
};

const initAds = (
    window: WindowWithGoogleTag &
        WindowWithPbjs &
        WindowWithClientData &
        WindowWithDataLayer &
        WindowWithGa &
        WindowWithAdvertising &
        WindowWithAdSense
) => {
    if (!window.clientData) {
        throw new Error('clientData not found on window object. Error id: 1uw2');
    }

    const userEmail = window.clientData.userData?.email;
    initLiveRamp(window, userEmail);

    initMicrosoftClarity(window);

    const bingVsAdSenseMobileVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_MOBILE, '');

    const bingVsAdSenseDesktopVariant =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().getFeatureValue(GrowthBookFeature.BING_VS_ADSENSE_DESKTOP, '');

    const { device, pageType, currentPage } = getPageData(window);
    const l1Category = (window as WindowWithClientData).clientData.displayAds?.config?.l1Category;

    const showAdSense = isAdSenseEnabled({
        pageType,
        l1Category,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        device,
        currentPage,
    });

    const showBing = isBingEnabled({
        pageType,
        l1Category,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        device,
        currentPage,
    });

    Promise.all([
        showBing && initBing(window),
        showAdSense && initAdSenseAds(window),
        initDisplay(window),
        initPrebid(window),
    ]);
};

export default initAds;
