import { useDispatch } from 'react-redux';
import {
    setIsOpen,
    setType,
    setIsLogin,
    showForgotPasswordModal,
    type LoginModalType,
    setRedirectUrl,
} from './reducers/common/login-modal';

export const useModal = () => {
    const dispatch = useDispatch();

    const openDialog = (
        event: React.MouseEvent | undefined,
        modalType: LoginModalType,
        showLogin: boolean,
        redirectUrl?: string
    ) => {
        if (event) {
            event.preventDefault();
        }
        dispatch(setIsOpen(true));
        dispatch(setType(modalType));
        dispatch(setIsLogin(showLogin));
        if (redirectUrl) {
            dispatch(setRedirectUrl(redirectUrl));
        }
    };

    const closeDialog = () => {
        dispatch(setIsOpen(false));
        dispatch(showForgotPasswordModal({ value: false }));
    };

    return { openDialog, closeDialog };
};
