import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Icon } from '@gumtree/ui-library';
import useWindowWidth from '@gumtree/ui-library/src/utils/use-window-size';
import { LoginModalType } from '@gumtree/shell/src/reducers/common/login-modal';
import { SocialLoginPayload } from './types';
import useFacebookSignIn from './useFacebookSignIn';
import useGoogleSignIn from './useGoogleSignIn';
import {
    StyledFacebookButton,
    StyledGoogleButtonWrapper,
    StyledFacebookLogoSvg,
    StyledFacebookLogoPng,
    StyledSocialFormWrapper,
    StyledErrorBox,
    StyledErrorTitle,
    StyledErrorDescription,
} from './style';

const SocialForm = ({ type, modalType, redirectUrl }: Props) => {
    const handleSubmitLogin = async ({ username, password, loginPlatform }: SocialLoginPayload) => {
        const resp = await fetch('/bff-api/login/via-social', {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                username,
                password,
                loginPlatform,
            }),
            credentials: 'include',
        });

        if (!resp.ok) {
            console.error(`Failed to login: ${resp.status}`);
        }

        const url = window.location.pathname;
        const urlParts = url.split('/');
        const adId = urlParts[urlParts.length - 1];

        if (modalType === LoginModalType.SELL) {
            window.location.assign('/postad/category');
        } else if (modalType === LoginModalType.FAVOURITE && redirectUrl) {
            window.location.assign(decodeURIComponent(redirectUrl));
        } else if (redirectUrl) {
            window.location.assign(redirectUrl);
        } else if (modalType === LoginModalType.EMAIL) {
            window.location.assign(`/reply/${adId}`);
        } else {
            window.location.reload();
        }
    };

    const { onLaunch, errorMessage: fbErrorMessage } = useFacebookSignIn(handleSubmitLogin, type);
    const {
        onSuccess,
        onError,
        errorMessage: googleErrorMessage,
    } = useGoogleSignIn(handleSubmitLogin, type);

    const errorMessage = fbErrorMessage || googleErrorMessage;

    const { width } = useWindowWidth();

    let buttonWidth;

    if (width > 280 && width < 767) {
        buttonWidth = 310;
    } else {
        buttonWidth = 400;
    }

    return (
        <StyledSocialFormWrapper data-testid="social-form-wrapper">
            {errorMessage && (
                <StyledErrorBox>
                    <StyledErrorTitle>
                        <Icon type="circ-error" />
                        Login Error
                    </StyledErrorTitle>
                    <StyledErrorDescription data-testid="social-error-message">
                        {errorMessage}
                    </StyledErrorDescription>
                </StyledErrorBox>
            )}
            <StyledGoogleButtonWrapper isLoginPage={modalType === null}>
                <GoogleLogin
                    onSuccess={onSuccess}
                    onError={onError}
                    text="continue_with"
                    logo_alignment="center"
                    ux_mode="popup"
                    width={modalType ? buttonWidth : undefined}
                />
            </StyledGoogleButtonWrapper>
            <StyledFacebookButton
                isLoginPage={modalType === null}
                type="button"
                data-testid="facebook-sign-in-btn"
                onClick={onLaunch}
            >
                {modalType ? <StyledFacebookLogoPng /> : <StyledFacebookLogoSvg />}
                Continue with Facebook
            </StyledFacebookButton>
        </StyledSocialFormWrapper>
    );
};

export default SocialForm;

interface Props {
    modalType: LoginModalType | null;
    redirectUrl?: string | null;
    type: 'login' | 'register';
}
