import { WindowWithClientData } from '@gumtree/shared/src/types/window';

export default (googletag, window: WindowWithClientData, pageType) => {
    if (!['ResultsSearch', 'ResultsBrowse'].includes(pageType) || !window.clientData) {
        return;
    }

    const naturalResultSize = 'resultsPage' in window.clientData ? window.clientData.resultsPage.searchAds.length : undefined;
    const nearbyResultSize = 'resultsPage' in window.clientData ? window.clientData.resultsPage?.nearbyAds?.ads.length : undefined;

    if (!naturalResultSize || !nearbyResultSize) {
        return;
    }

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('res_ntrl', `${naturalResultSize}`);
        googletag.pubads().setTargeting('res_nrby', `${nearbyResultSize}`);
        googletag.pubads().setTargeting('res_cmb', `${naturalResultSize}-${nearbyResultSize}`);
    });
};
