import { ShellState } from '../reducers/common';

export { trackV2 } from '@gumtree/shared/src/util/track-ga-event';

export const navigate = (url: string) => ({
    type: 'NAVIGATE_TO_URL',
    url,
});

/** Redirect to `baseConfig.loginUrl` and then return to current url */
export const loginThenComeBack = () => {
    return (dispatch, getState: () => ShellState) => {
        const {
            baseConfig: { buyerUrl },
        } = getState();

        dispatch(navigate(`${buyerUrl}/login?cb=${encodeURIComponent(window.location.href)}`));
    };
};

export const launchBanner = (target: string) => ({
    type: 'OPEN_BANNER_TAKEOVER',
    target,
});

export const trackAndNavigate = (_eventName: string, url: string, _eventLabel?: string) => {
    return (dispatch) => {
        dispatch(navigate(url));
    };
};
