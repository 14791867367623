import { TextAdConditions, whichTextAdType } from '../util/text-ads-util';

export const isBingEnabled = ({
    pageType,
    l1Category,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    device,
    currentPage,
}: TextAdConditions) =>
    whichTextAdType({
        pageType,
        l1Category,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        device,
        currentPage,
    }) === 'BING';
