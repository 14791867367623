import getBingAdConfig from './ad-config/build-page-config';

import sendImpressionFeedback from './send-impression-feedback';
import renderAttributions from './components/attribution/renderer';
import renderSlots from './renderer';
import { validatePageConfig } from './ad-config/ad-config-validator';
import findSlotsOnDom from './slots-on-dom-locator';
import fetchBingAds from './fetch-bing-ads';
import transformDataIntoAdModels from '../common/transform-data-into-ad-models';
import buildUrl from './bing-server-url-constructor';
import suppressTextAdSpace from '../../suppress-text-ad-space';
import { TextAdConditions } from '../../util/text-ads-util';
import { sendGa4Impression } from './send-ga4-impression';

export type DataLayer = {
    push: (attributes: {
        event: string;
        eventCategory: string;
        eventAction?: string;
        eventLabel?: string;
    }) => undefined;
};

const loader = async ({
    device,
    keywords,
    clientIp,
    l1Category,
    googlePpcVisit,
    pageType,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    currentPage,
    numberOfResults,
    window,
}: TextAdConditions & {
    numberOfResults: number;
    keywords: string;
    clientIp: string;
    googlePpcVisit: boolean;
    window: Window;
}) => {
    if (!device || !l1Category || !pageType) {
        return;
    }

    const pageConfig = getBingAdConfig({
        device,
        l1Category,
        googlePpcVisit,
        pageType,
    });

    if (!validatePageConfig(pageConfig)) {
        return Promise.resolve('No valid Bing config found on page');
    }

    const bingUrl = buildUrl({
        device,
        l1Category,
        keywords,
        pageType,
        clientIp,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        currentPage,
        numberOfResults,
        isGooglePpcVisit: googlePpcVisit,
        mainlineCount: pageConfig.number,
    });

    const slotsOnDom = findSlotsOnDom(pageConfig.slots, window);

    if (slotsOnDom.length === 0) {
        return Promise.resolve('No Bing slot found on DOM');
    }

    try {
        const { bingAds, impressionFeedbackUrl, visibilityFeedbackUrl } = await fetchBingAds(
            bingUrl
        );

        bingAds.length > 0
            ? renderAttributions(pageConfig.slots, bingAds)
            : suppressTextAdSpace(pageType);

        const adModels = transformDataIntoAdModels(bingAds);

        const renderedModels = renderSlots({
            slotsOnDom,
            device,
            adModels,
            serverConfig: pageConfig,
            pageType,
            visibilityFeedbackBaseUrl: visibilityFeedbackUrl,
        });

        sendGa4Impression({ adModels });

        sendImpressionFeedback({
            renderedAds: renderedModels,
            impressionFeedbackUrl,
        });
    } catch (_ex) {
        suppressTextAdSpace(pageType);
        return Promise.resolve('Bing connection error');
    }

    return Promise.resolve();
};

export default loader;
