import { generateActionPayload } from './transform';
import { queueCustomEvent } from './queuing-system';

declare global {
    interface Window {
        clientData?: any;
        Gum?: any;
        permutive?: {
            track: (name: 'Action', payload: any, options: {}) => {};
            addon: (name: 'web', payload: any) => {};
        };
    }
}

export function trackImmediately(window: Window, payload: object): Promise<void> {
    return new Promise((resolve) => {
        try {
            window.permutive?.track('Action', payload, {
                error(errors) {
                    console.error(`PERMUTIVE: ${errors}`);
                },
            });
        } catch (error) {
            console.error(`PERMUTIVE: ${error}`);
        } finally {
            resolve();
        }
    });
}

export function trackPermutiveCustomEvent(
    window: Window,
    eventName: string,
    sellerType?: string,
    isQueueEvent = false
) {
    if (!window.permutive) return;

    let userLoggedIn: any;
    let deviceSize;

    userLoggedIn = window?.clientData?.userData
        ? window?.clientData?.userData.userLoggedIn
        : window?.clientData?.userData;

    deviceSize = window?.clientData?.baseConfig?.device?.type;

    const payload = generateActionPayload(
        window.dataLayer[0],
        deviceSize,
        userLoggedIn,
        eventName,
        sellerType
    );

    if (isQueueEvent) {
        queueCustomEvent(window, payload);
    } else {
        trackImmediately(window, payload);
    }
}
