import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';
import { AdsType, getAdsTypeToShow } from '../common/ad-type-selector';
import { createTracking, TextAdConditions } from '../../util/text-ads-util';
import {
    buildAdTypesPreference,
    buildAppId,
    buildFilterType,
    encodeKeywords,
} from '../common/url-constructor-util';

const buildAdUnitId = (
    adsType: AdsType,
    device: Device | undefined,
    l1Category: L1Category | undefined,
    isGooglePpcVisit: boolean,
    pageType: PageType | undefined
) => {
    if (pageType && ['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        if (device === 'mobile') {
            if (isGooglePpcVisit || l1Category === 'jobs') {
                return '11649760';
            } else if (adsType === AdsType.ProductWithBackfill) {
                return '11707002';
            } else {
                return '11649760';
            }
        } else if (device === 'tablet') {
            if (adsType === AdsType.ProductWithBackfill) {
                return '11707001';
            } else {
                return '11649762';
            }
        } else if (device === 'desktop') {
            return '11700036';
        }
    } else if (pageType === 'VIP') {
        if (device === 'desktop') {
            return '11720127';
        }
        if (device === 'tablet') {
            return '11720128';
        }
        if (device === 'mobile') {
            return '11720126';
        }
    }

    return undefined;
};

export default ({
    device,
    l1Category,
    keywords,
    pageType,
    clientIp,
    isGooglePpcVisit,
    mainlineCount,
    currentPage,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    numberOfResults,
}: TextAdConditions & {
    keywords: string;
    clientIp: string | undefined;
    isGooglePpcVisit: boolean;
    mainlineCount: number;
    numberOfResults: number;
}): string => {
    const adsType: AdsType = getAdsTypeToShow(l1Category);
    const appId = buildAppId(adsType);
    const adUnitId = buildAdUnitId(adsType, device, l1Category, isGooglePpcVisit, pageType);
    const supportedAdExtensions =
        adsType === AdsType.Text || adsType === AdsType.ProductWithBackfill
            ? encodeURIComponent('SiteLinks,LongAdTitle')
            : '';
    const tracingTag = createTracking({
        pageType,
        l1Category,
        currentPage,
        device,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        numberOfResults,
    });
    const filterType: string = buildFilterType(adsType);
    const adTypesPreference = buildAdTypesPreference(adsType);

    const lowerCasedQuery = keywords.toLowerCase(); // lowercase generates more PLA results see GTF-2247

    const queryParameters = [
        { key: 'appid', value: appId },
        { key: 'propertyId', value: 10278676 },
        { key: 'q', value: encodeKeywords(lowerCasedQuery) },
        { key: 'adUnitId', value: adUnitId },
        { key: 'supportedAdExtensions', value: supportedAdExtensions },
        { key: 'clientIP', value: clientIp },
        { key: 'mainlineCount', value: mainlineCount.toString() },
        { key: 'sidebarCount', value: '0' },
        { key: 'safeSearch', value: 'strict' },
        { key: 'mkt', value: 'en-GB' },
        { key: 'tracingTag', value: tracingTag },
        { key: 'adTypesFilter', value: filterType },
        { key: 'AdTypesPreference', value: adTypesPreference },
    ];

    const url = `/partner/bing?${queryParameters
        .filter(({ value }) => Boolean(value))
        .map(({ key, value }) => `${key}=${value}`)
        .join('&')}`;

    return url;
};
