import transformProductAd from './transformers/product-ad';
import { isProductAd, isTextAd } from './domain/ad-qualifier';
import transformTextAd from './transformers/text-ad';

export type Offer = {
    url: string;
    urlPingSuffix: string;
    seller: {
        name: string;
    };
    price: number;
    priceCurrency: string;
    itemOffered: {
        name: string;
        image: {
            contentUrl: string;
            width: number;
            height: number;
        };
        description: string;
    };
    priceCurrencySymbol: string;
    lowPrice?: number;
};

export type AdParamsFromBingApi = {
    _type: 'Ads/TextAd' | 'Ads/ProductAd';
    id: string;
    url?: string;
    urlPingSuffix: string;
    description?: string;
    rank: number;
    position: 'Mainline';
    impressionToken: string;
    title?: string;
    displayUrl?: string;
    isAdult: boolean;
    phoneNumber?: string;
    extensions?: Array<{
        _type: 'Ads/CreativeAnnotation' | 'Ads/CalloutExtension' | 'Ads/SiteLinkExtension';
        impressionToken: string;
        layoutId?: number;
        callout?: string;
        siteLinks?: Array<{
            text: string;
            link: string;
            linkPingSuffix: string;
            impressionToken: string;
        }>;
        overrides?: Array<{
            title: string;
            description: string;
            maxTitleLength: number;
            impressionToken: string;
        }>;
    }>;
    offer?: Offer;
    primaryImageInfo?: {
        colorInfo: {
            backgroundColor: string;
            layoutColor: string;
            fontColor: string;
        };
    };
    shippingInfo?: {
        description: string;
        price: number;
    };
    entityInfos?: Array<{
        entityIdType: string;
        entityIds: string[];
    }>;
};

export type AdModel = {
    num: number;
    _type: 'Ads/TextAd' | 'Ads/ProductAd';
    url: string;
    title?: string;
    description: string;
    seller: string;
    price: {
        sale?: any;
        original: number;
        currency: string;
    };
    item: {
        name: string;
        description: string;
        image: {
            url: string;
        };
    };
    displayUrl?: string;
    position: 'Mainline';
    impressionToken: string;
    siteLinks?: object[];
    clickToCallNumbers?: string[];
};

export const hasProductAd = (ads: AdModel[]) => ads.some((ad) => isProductAd(ad));

const assignNumByType = () => {
    let textAdIndex = 1;
    let productAdIndex = 1;
    return (ad) => {
        const num = isTextAd(ad) ? textAdIndex++ : productAdIndex++;
        return { num, ...ad };
    };
};

const transformDataIntoAdModels = (bingAds: AdParamsFromBingApi[]): AdModel[] =>
    bingAds
        .map((ad) => (isTextAd(ad) ? transformTextAd(ad) : transformProductAd(ad)))
        .map(assignNumByType());

export default transformDataIntoAdModels;
