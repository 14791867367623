/* eslint-disable react/no-danger */
import React from 'react';
import { TextAdConditions, whichTextAdType } from '../util/text-ads-util';

export const isAdSenseEnabled = ({
    pageType,
    l1Category,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    device,
    currentPage,
}: TextAdConditions) =>
    whichTextAdType({
        pageType,
        l1Category,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        device,
        currentPage,
    }) === 'ADSENSE';

const LibraryTag = () => <script async src="https://www.google.com/adsense/search/ads.js" />;

export function InitialisationTag({
    pageType,
    l1Category,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    device,
    currentPage,
}: TextAdConditions) {
    return isAdSenseEnabled({
        pageType,
        l1Category,
        bingVsAdSenseMobileVariant,
        bingVsAdSenseDesktopVariant,
        device,
        currentPage,
    }) ? (
        <>
            <LibraryTag />
            <script
                dangerouslySetInnerHTML={{
                    __html: `
            (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
            arguments)},g[o]['t']=1*new Date})(window,'_googCsa');
            `,
                }}
            />
        </>
    ) : null;
}
