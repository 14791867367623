import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';
import { isDesktop, isMobile } from '../../common/domain/device';
import { AdsType, getAdsTypeToShow } from '../../common/ad-type-selector';
import { determineSlots } from '../ad-slot-selector/ad-slot-selector';

export type PageConfig = {
    slots: SlotConfig[];
    number: number;
    maxTop: number;
};

export type SlotConfig = {
    divId: 'slot12' | 'slot15';
    isCarousel: boolean;
};

export const getResultsPageMaxTop = ({
    activeSlots,
}: {
    activeSlots: ('slot12' | 'slot15')[];
}): number => (!activeSlots.find((slotId) => slotId === 'slot12') ? 0 : 1);

const buildPageConfig = ({
    device,
    l1Category,
    pageType,
    googlePpcVisit,
}: {
    device: Device;
    l1Category: L1Category;
    pageType: PageType;
    googlePpcVisit: boolean;
}): PageConfig => {
    const emptyConfig = {
        slots: [],
        maxTop: 0,
        number: 0,
    };

    if (!device) {
        return emptyConfig;
    }

    const getSlotConfig = (divId, device, l1Category) => ({
        divId,
        isCarousel:
            getAdsTypeToShow(l1Category) === AdsType.ProductWithBackfill &&
            (isMobile(device) || (isDesktop(device) && divId === 'slot12')),
    });

    const activeSlots = determineSlots({
        googlePpcVisit,
        pageType,
    });

    if (['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        const distributionDictionary = {
            desktop: {
                maxTop: 3,
                number: 3,
            },
            tablet: {
                maxTop: 3,
                number: 3,
            },
            mobile: {
                maxTop: 1,
                number: 1,
            },
        };

        return {
            slots: activeSlots.map((slotId) => getSlotConfig(slotId, device, l1Category)),
            maxTop: getResultsPageMaxTop({
                activeSlots,
            }),
            number: activeSlots.length === 0 ? 0 : distributionDictionary[device].number,
        };
    }

    return emptyConfig;
};

export default buildPageConfig;
