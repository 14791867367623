import React from 'react';

import type { BrowseLinkDef } from '@gumtree/shared/src/types/web-bff';
import { LinkList } from '@gumtree/ui-library';
import slug from '@gumtree/ui-library/src/utils/slug';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackGA4AndNavigate } from '@gumtree/shared/src/util/ga4-shared';
import { getLinkHostname } from '@gumtree/shared/src/util/ga4-bff';

import { mapLocationToLink } from './browse-links-service';

export default function TopLocationItem({ items, title, showTitle }: Props) {
    return (
        <>
            {showTitle && <h4 {...qaAttribute(`top-location-${slug(title)}`)}>{title}</h4>}
            <LinkList
                items={items.map(mapLocationToLink)}
                onClickItem={(e, item) =>
                    trackGA4AndNavigate<GA4.ClickFooterTop>({
                        href: item.href,
                        mouseEvent: e,
                        payload: {
                            event: 'click_footer_top_locations',
                            linkDomain: getLinkHostname(item.href),
                            linkHeader: title,
                            linkText: item.text,
                            linkType: 'list link',
                            linkURL: item.href,
                        },
                    })
                }
            />
        </>
    );
}

interface Props {
    items: BrowseLinkDef['items'];
    showTitle: boolean;
    title: string;
}
