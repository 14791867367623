const channelMapping = new Map<string, number>([
    ['web_mobile_ZSRP_toplevel_page2_GTB-16-B', 1504605625],
    ['web_mobile_ZSRP_for-sale_page2_GTB-16-B', 1733774134],
    ['web_mobile_SRP_toplevel_page2_GTB-16-B', 1244751174],
    ['web_mobile_SRP_for-sale_page2_GTB-16-B', 7065991861],
    ['web_mobile_BRP_toplevel_page2_GTB-16-B', 7899156415],
    ['web_mobile_BRP_for-sale_page2_GTB-16-B', 2200677990],
    ['web_desktop_ZSRP_toplevel_page2_GTB-16-B', 2626115607],
    ['web_desktop_ZSRP_for-sale_page2_GTB-16-B', 7181541932],
    ['web_desktop_SRP_toplevel_page2_GTB-16-B', 5401556404],
    ['web_desktop_SRP_for-sale_page2_GTB-16-B', 9117440130],
    ['web_desktop_BRP_toplevel_page2_GTB-16-B', 5150274964],
    ['web_desktop_BRP_for-sale_page2_GTB-16-B', 9286892729],
    ['web_mobile_ZSRP_toplevel_page1_GTB-16-C', 9024002990],
    ['web_mobile_ZSRP_for-sale_page1_GTB-16-C', 1015588836],
    ['web_mobile_SRP_toplevel_page1_GTB-16-C', 2883204274],
    ['web_mobile_SRP_for-sale_page1_GTB-16-C', 3350108134],
    ['web_mobile_BRP_toplevel_page1_GTB-16-C', 5806812712],
    ['web_mobile_BRP_for-sale_page1_GTB-16-C', 9636077206],
    ['web_desktop_ZSRP_toplevel_page1_GTB-16-C', 6206267967],
    ['web_desktop_ZSRP_for-sale_page1_GTB-16-C', 5868460269],
    ['web_desktop_SRP_toplevel_page1_GTB-16-C', 8548526129],
    ['web_desktop_SRP_for-sale_page1_GTB-16-C', 9937629411],
    ['web_desktop_BRP_toplevel_page1_GTB-16-C', 7373113622],
    ['web_desktop_BRP_for-sale_page1_GTB-16-C', 6756932307],
]);

export const getChannelId = (channelName: string): number | undefined => {
    return channelMapping.get(channelName);
};
