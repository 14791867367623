import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { GrowthBookFeature } from '@gumtree/shared/src/model/experiment';
import { Icon } from '@gumtree/ui-library';
import { POLLING_INTERVAL_MS } from '@gumtree/shared/src/constants/message-centre';
import type { ShellState } from '../../../reducers/common';
import { MessagesButton, StyledCountBullet, StyledWrapper } from './style';
import { fetchUnreadMessagesCount } from '../../../reducers/common/unread-messages';
import { ShellUseDispatch } from '../../../reducers/common';
import logger from '../../../logger';

export default function NavBarMessagesButton() {
    const dispatch = useDispatch() as ShellUseDispatch;
    const {
        baseConfig: { bffUrl, pageType },
        unreadMessagesCount,
    } = useSelector(selectState);

    const showUnreadCount = useFeatureIsOn(GrowthBookFeature.UNREAD_FEATURE_FLAG);

    useEffect(() => {
        if (showUnreadCount === false || pageType === 'MyMessages') {
            return;
        }

        let intervalId = 0;
        async function intervalCallback() {
            try {
                const shouldPoll = await dispatch(fetchUnreadMessagesCount());
                if (!shouldPoll) {
                    clearInterval(intervalId);
                }
            } catch (error: any) {
                logger.error('Error fetching unread messages count:', error);
            }
        }
        intervalId = window.setInterval(intervalCallback, POLLING_INTERVAL_MS);
        intervalCallback();

        return () => clearInterval(intervalId);
    }, [pageType, showUnreadCount]);

    const unreadMessagesCountToDisplay = Math.min(unreadMessagesCount, 99);

    return (
        <StyledWrapper>
            <MessagesButton
                {...qaAttribute('my-gumtree-manage-messages')}
                data-testid="my-gumtree-manage-messages"
                aria-label="Messages"
                className="messages-button"
                dark
                label="Messages"
                icon={<Icon type="envelope" />}
                href={`${bffUrl}/manage/messages`}
                bottomLabel
                onClick={() => trackV2('manageMessagesHeader')}
            />
            {showUnreadCount && Boolean(unreadMessagesCount) && (
                <StyledCountBullet>{unreadMessagesCountToDisplay}</StyledCountBullet>
            )}
        </StyledWrapper>
    );
}

function selectState({ baseConfig, unreadMessagesCount, experiments }: ShellState) {
    return {
        baseConfig,
        unreadMessagesCount,
        experiments
    };
}
