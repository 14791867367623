import type { WindowWithGoogleTag } from '@gumtree/shared/src/types/window';
import type { AdSlot, GoogleTag } from '@gumtree/shared/src/types/advertising/dfp';

import {
    getCurrentBreakpoint,
    getBreakpointMinByTshirtSize,
    ScreenSize,
} from '@gumtree/shared/src/util/breakpoints-service';
import { PageConfig } from '@gumtree/third-party-ads/src/framework/dfp/ad-config/build-page-config';
import type { SlotName } from '@gumtree/third-party-ads/src/framework/dfp/ad-config/slot-configs';
import type { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';

import { getWhitelistedSlotIds } from './refresh-whitelist';

class DisplayAdSlot {
    private mapping: PageConfig[number]['mapping'] = [];
    public adSlot?: AdSlot;
    private isGoogleDisplayCalled = false;
    private mediaQueries: string[] = [];
    private spec: PageConfig[number];
    private googletag: GoogleTag;

    constructor(
        pageType: string,
        config: PageConfig[number],
        googletag: GoogleTag,
        w: WindowWithGoogleTag
    ) {
        this.spec = config;
        this.googletag = googletag;

        this.setupMapping();
        this.define(pageType, w);
    }

    public isSlotIdInList(list: SlotName[]): boolean {
        return list.includes(this.spec.slotId);
    }

    private setupMapping() {
        if (Array.isArray(this.spec.mapping)) {
            const mediaQueries = new Set<string>();

            this.spec.mapping.forEach(({ screenSize }) => {
                screenSize.forEach((size) => mediaQueries.add(size));
            });

            this.mediaQueries = [...mediaQueries];

            this.googletag.cmd.push(() => {
                let mapping = this.googletag.sizeMapping();

                this.spec.mapping
                    .map(({ screenSize, screenHeight, adSize }) => {
                        const minWidth = getBreakpointMinByTshirtSize(screenSize[0]);
                        return { screenSize: [minWidth, screenHeight || 0], adSize };
                    })
                    .forEach(({ screenSize, adSize }) => {
                        mapping = mapping.addSize(screenSize, adSize);
                    });

                this.mapping = mapping.build();
            });
        }
    }

    private checkMQ(w: WindowWithGoogleTag): boolean {
        return this.mediaQueries.includes(getCurrentBreakpoint(w)?.tshirt as string);
    }

    private checkDom(w: WindowWithGoogleTag): boolean {
        return Boolean(w.document.getElementById(this.spec.slotId));
    }

    isRefreshable(
        device: Device | undefined,
        l1Category: L1Category,
        pageType: PageType,
        w: WindowWithGoogleTag
    ): boolean {
        const currentBreakpoint = getCurrentBreakpoint(w);
        if (!currentBreakpoint) {
            throw new Error('Cannot determine screen size. Error id: 11e3');
        }
        const currentScreenSize = currentBreakpoint.tshirt;

        const whitelistedSlotIds =
            getWhitelistedSlotIds(device, l1Category, pageType, currentScreenSize) ?? [];

        return whitelistedSlotIds.includes(this.spec.slotId);
    }

    callGoogleDisplay(w: WindowWithGoogleTag) {
        if (this.checkMQ(w) && !this.isGoogleDisplayCalled) {
            this.googletag.cmd.push(() => {
                this.googletag.display(this.spec.slotId);
            });

            this.isGoogleDisplayCalled = true;
        }
    }

    private startWithCollapsedSlot(adSlot: AdSlot) {
        return adSlot.setCollapseEmptyDiv(true, true);
    }

    private collapseSlotIfEmpty(adSlot: AdSlot) {
        return adSlot.setCollapseEmptyDiv(true);
    }

    private define(pageType: string, w: WindowWithGoogleTag) {
        if (this.adSlot) {
            return;
        }

        this.googletag.cmd.push(() => {
            const screenTShirtSize = getCurrentBreakpoint(w)?.tshirt as ScreenSize;

            const { adSize } =
                this.spec.mapping.find(({ screenSize }) => screenSize.includes(screenTShirtSize)) ||
                {};

            if (adSize) {
                const adUnit = this.spec.adUnit[screenTShirtSize];
                const slot = this.googletag
                    // @ts-ignore
                    .defineSlot(adUnit, adSize, this.spec.slotId)
                    .defineSizeMapping(this.mapping)
                    .setTargeting('ad_h', new Date().getUTCHours().toString())
                    .addService(this.googletag.pubads());

                if (
                    this.spec.startWithCollapsedSlot &&
                    this.spec.startWithCollapsedSlot.some(
                        ({ pageType: pType, screenSizes }) =>
                            pType === pageType && screenSizes.includes(screenTShirtSize)
                    )
                ) {
                    this.startWithCollapsedSlot(slot);
                } else {
                    this.collapseSlotIfEmpty(slot);
                }

                if (this.spec.targeting) {
                    Object.entries(this.spec.targeting).forEach(([key, value]) =>
                        slot.setTargeting(key, value)
                    );
                }

                this.adSlot = slot;
            }
        });
    }
}

export default DisplayAdSlot;
