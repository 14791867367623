import { AdsType } from './ad-type-selector';

export const APP_ID_BACKFILL = '8ED44B28311A63CF32CCBBDA850C072B30E41182';
export const APP_ID_DEFAULT = 'CFEAB5E0AC88686BA597A26FB4B545552FCA87AE';

export const buildAdTypesPreference = (adsType: AdsType) => {
    switch (adsType) {
        case AdsType.ProductWithBackfill:
            return 'ProductAds';
        default:
            return '';
    }
};

export const buildAppId = (adsType: AdsType) => {
    return adsType === AdsType.ProductWithBackfill ? APP_ID_BACKFILL : APP_ID_DEFAULT;
};

export const buildFilterType = (adsType: AdsType): string => {
    switch (adsType) {
        case AdsType.ProductWithBackfill:
            return 'TextAds%2CProductAds';
        default:
            return 'TextAds';
    }
};

export const encodeKeywords = (unencodedKeywords) =>
    unencodedKeywords
        ?.replace(/ /g, '+')
        .replace(/&/g, '%26')
        .replace(/([^a-zA-Z0-9+%]*)/g, '');
