import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon, TypeAhead } from '@gumtree/ui-library/src';
import type { ShellUseDispatch } from '@gumtree/shell/src/reducers/common';

import { LocationList } from '../fetch-from-locations';
import { onInput, showLocationSuggestions, submitSearchHandler } from './event-handlers';
import { StyledLocationField } from './common.style';
import { trackClickSearchBar } from '../../header/search-bar/search-bar-actions';

const LocationSearch = ({
    isSearchSubmitted,
    setIsSearchSubmitted,
    location,
    setLocation,
}: {
    isSearchSubmitted: boolean;
    setIsSearchSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    location: string;
    setLocation: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const dispatch = useDispatch() as ShellUseDispatch;
    const [locationInputRef, setLocationInputRef] = useState<HTMLInputElement | null>(null);
    const [locationSuggestions, setLocationSuggestions] = useState<LocationList>([]);

    useEffect(() => {
        if (locationInputRef) {
            locationInputRef.focus();
        }
    }, [locationInputRef]);

    return (
        <StyledLocationField>
            <Icon type="beacon" size="medium" className="location-input-icon" />
            <TypeAhead
                inputProps={{
                    'aria-label': 'Location',
                    onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                        onInput(e, location, setLocationSuggestions);
                    },
                    placeholder: 'Postcode or location',
                }}
                inputRef={(element: HTMLInputElement): void => {
                    setLocationInputRef(element);
                }}
                id="header-search-location"
                limit={10}
                name="search_location"
                onChange={(value) => {
                    setLocation(value);
                    dispatch(trackClickSearchBar('location', locationSuggestions.length));
                }}
                onSelectOption={(value) => {
                    submitSearchHandler(value.name, setIsSearchSubmitted, 'suggested').then();
                }}
                typeAheadIcon={<Icon type="beacon" size="medium" />}
                options={showLocationSuggestions(location) ? locationSuggestions : []}
                titleOfRecentHistory="Recent Locations"
                showClearAllButton
            />
            <Button
                className="search-button"
                display="primary"
                isLoading={isSearchSubmitted}
                loadingText=""
                label={<Icon type="magnifying-glass" />}
                type="submit"
            />
        </StyledLocationField>
    );
};

export default LocationSearch;
