import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { TextAdSlotDivNames } from '../../components/text-ad-slots';

export const sendGa4Impression = ({
    containerName,
    adsLoaded,
    window,
}: {
    containerName: 'slot12' | 'slot15';
    adsLoaded: boolean;
    window: Window;
}) => {
    if (containerName === TextAdSlotDivNames.slot12) {
        if (adsLoaded) {
            const slot12 = window?.document?.getElementById(containerName);
            if (!slot12) {
                return;
            }
            if (slot12.clientHeight <= 280) {
                trackGA4Event<GA4.TextAdImpression>({
                    event: 'advertising_text_ad_impression',
                    advertisingTextAdProvider: 'AdSense',
                    advertisingTextAdType: 'text',
                    advertisingTextAdIsFilled: adsLoaded,
                    advertisingTextAdSlotHeight: slot12.clientHeight,
                });
            } else if (slot12.clientHeight > 280 && slot12.clientHeight < 360) {
                trackGA4Event<GA4.TextAdImpression>({
                    event: 'advertising_text_ad_impression',
                    advertisingTextAdProvider: 'AdSense',
                    advertisingTextAdType: 'product',
                    advertisingTextAdIsFilled: adsLoaded,
                    advertisingTextAdSlotHeight: slot12.clientHeight,
                });
            } else {
                trackGA4Event<GA4.TextAdImpression>({
                    event: 'advertising_text_ad_impression',
                    advertisingTextAdProvider: 'AdSense',
                    advertisingTextAdType: 'unrecognised',
                    advertisingTextAdIsFilled: adsLoaded,
                    advertisingTextAdSlotHeight: slot12.clientHeight,
                });
            }
        } else {
            trackGA4Event<GA4.TextAdImpression>({
                event: 'advertising_text_ad_impression',
                advertisingTextAdProvider: 'AdSense',
                advertisingTextAdType: 'unfilled',
                advertisingTextAdIsFilled: adsLoaded,
                advertisingTextAdSlotHeight: undefined,
            });
        }
    }
};
