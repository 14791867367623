import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css } from '@emotion/react';

const Transition = ({
    children,
    duration,
    id,
    in: inProp,
    startMounted,
    getStartStyle,
    getEndStyle,
}) => {
    const [status, setStatus] = useState(startMounted ? 'entered' : 'exited');
    const [style, setStyle] = useState({});
    const elemRef = useRef(null);

    const getClasses = () => {
        const base = id;
        switch (status) {
            case 'entering':
                return `${base} ${base}-enter-active`;
            case 'exiting':
                return `${base} ${base}-exit-active`;
            case 'entered':
                return `${base} ${base}-enter-done`;
            case 'exited':
                return `${base} ${base}-exit-done`;
            default:
                return base;
        }
    };

    const animationStyle = css({
        transition: `all ${duration}ms ease-in-out`,
        ...style,
    });

    const handleEntering = (elem) => {
        setStyle({
            ...getEndStyle(elem),
            transition: `all ${duration}ms ease-in-out`,
        });
        setTimeout(() => {
            setStatus('entered');
        }, duration);
    };

    const handleExiting = (elem) => {
        setStyle({
            ...getStartStyle(elem),
            transition: `all ${duration}ms ease-in-out`,
        });
        setTimeout(() => {
            setStatus('exited');
        }, duration);
    };

    useEffect(() => {
        if (elemRef.current) {
            if (inProp) {
                setStatus('entering');
                handleEntering(elemRef.current);
            } else {
                setStatus('exiting');
                handleExiting(elemRef.current);
            }
        }
    }, [inProp]);

    useEffect(() => {
        if (elemRef.current && startMounted) {
            setStyle({
                ...getStartStyle(elemRef.current),
            });
        }
    }, [startMounted]);

    return (
        <>
            {(inProp || startMounted || status !== 'exited') &&
                React.cloneElement(children, {
                    ...children.props,
                    ref: elemRef,
                    className: classnames(children.props.className, getClasses()),
                    style: { ...children.props.style, ...style },
                    css: animationStyle.styles,
                })}
        </>
    );
};

Transition.defaultProps = {
    children: null,
    duration: 300,
    getEndStyle: () => ({}),
    getStartStyle: () => ({}),
    id: 'transition',
    in: false,
    startMounted: false,
};

Transition.propTypes = {
    children: PropTypes.node,
    duration: PropTypes.number,
    getEndStyle: PropTypes.func,
    getStartStyle: PropTypes.func,
    id: PropTypes.string,
    in: PropTypes.bool,
    startMounted: PropTypes.bool,
};

export default Transition;
