/* eslint-disable no-underscore-dangle */
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore, compose as composeOrig } from 'redux';
import thunk from 'redux-thunk';
import { CacheProvider } from '@emotion/react';

import * as Sentry from '@sentry/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';

import initAds from '@gumtree/third-party-ads/init-ads';
import trackConsentState from '@gumtree/third-party-ads/src/framework/gdpr/track-consent-state';
import trackingEventsOnOneTrust from '@gumtree/third-party-ads/src/framework/gdpr/tracking-events';
import initOneTrustImplicitConsent from '@gumtree/third-party-ads/src/framework/gdpr/onetrust-implicit-consent';
import setPermutiveConsent from '@gumtree/third-party-ads/src/framework/permutive/set-permutive-consent';
import { processPermutiveQueue } from '@gumtree/shared/src/util/permutive/queuing-system';
import iframeGaEventListener from '@gumtree/shared/src/partnerships/analytics/iframe-ga-event-listener';

import 'normalize.css/normalize.css';
import '@gumtree/ui-library/src/base/base.scss';
import '@gumtree/ui-library/src/typography/typography.scss';
import { addBlockthrough } from '@gumtree/third-party-ads/src/framework/blockthrough/add-blockthrough';

import { markDuckDuckGoCookie } from '@gumtree/shared/src/util/ad-block-util';
import createAppWith from './create-app';
import loadView from './load-view';
import initComScore from './com-score/init-com-score';

import logger from './logger';

import initGumtreeApi from './gumtree-api/init';
import qualtricsHelperInit from '../../syi/src/services/qualtrics-helper';
import { pageNames } from './route-map';
import initSentry from './sentry/init-sentry';
import { initForcedFeatures, initGrowthbook } from './growthbook/init-growthbook';
import { createEmotionCache } from '@gumtree/web-bff/src/render/create-emotion-cache';

/** decodes clientData after it was encoded by SSR */
window.clientData = JSON.parse(decodeURIComponent(window?.clientData));

const { clientData: data, growthbookExperimentData, experimentOverrides } = window;

const { page, baseConfig, experiments } = data;

const parsedExperimentData = JSON.parse(decodeURIComponent(growthbookExperimentData));
const growthbook = initGrowthbook(
    parsedExperimentData,
    page.appViewDevice,
    page.gbUserPseudoId,
    true
);
window.growthbook = growthbook;

const parsedExperimentOverrides = JSON.parse(decodeURIComponent(experimentOverrides));
initForcedFeatures(growthbook, parsedExperimentOverrides);

initSentry(
    baseConfig.isDev,
    baseConfig.environment,
    baseConfig.sentryDSN,
    experiments,
    page.appViewDevice
);
initGumtreeApi(window);
qualtricsHelperInit(window);
setPermutiveConsent(window);
processPermutiveQueue(window);

initAds(window);
Math.random() < 0.001 && trackConsentState(window);
trackingEventsOnOneTrust(window);
initOneTrustImplicitConsent(window);
iframeGaEventListener();

const composeEnhancers =
    (baseConfig.showReduxDevTools &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    composeOrig;

initComScore(window);

window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
});

function unregisterServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (const registration of registrations) {
                    registration.unregister();
                }
            })
            .catch(function (err) {
                logger.error('Service Worker registration failed: ', err);
            });
    }
}

const invokeSplitSignal = (w) => {
    // https://www.semrush.com/kb/1150-integration-with-react-applications#step-2-integrate-sp
    w.SM_SPLITSIGNAL_READY = true;

    w.dispatchEvent(new Event('splitsignal.initialized'));
};

loadView(page.type).then(({ App, reducers }) => {
    // const cache = createEmotionCache();
    page.App = App;

    const middlewares = [thunk];
    const store = createStore(
        combineReducers({ request: (state = {}) => state, ...reducers }),
        data,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    unregisterServiceWorker();

    try {
        hydrateRoot(
            document.querySelector('#content'),
            // <CacheProvider value={cache}>
            <GrowthBookProvider growthbook={growthbook}>
                <BrowserRouter>{createAppWith(store)}</BrowserRouter>
            </GrowthBookProvider>
            // </CacheProvider>
        );
    } catch (e) {
        Sentry.captureException(e);
        throw e;
    }

    if (typeof window !== 'undefined') {
        import('lazysizes').then((lazy) => lazy.init());
    }

    if (window.Cypress) {
        window.store = store;
    }

    markDuckDuckGoCookie(window);

    invokeSplitSignal(window);

    if (page.type === pageNames.SRP) {
        addBlockthrough(window);
    }
});
