import axios from 'axios';
import logger from '../logger';

export default abstract class MetricsRegistry {
    abstract getModuleName(): string;

    abstract getCountersToRegister(): any[];
    abstract getHistogramsToRegister(): any[];

    public static IncrementValidCounter(name, data) {
        try {
            const client = require('prom-client');
            const metric = client.register.getSingleMetric(name);

            if (!metric) {
                logger.warn(`Attempted to use counter that doesn't exist: ${name}`);
                return;
            }

            metric.inc(data);
        } catch (err: any) {
            logger.warn(`Failed to increment counter for ${name}`);
            logger.warn(err.stack);
        }
    }

    public static ObserveHistogram(name, observeNumber, data) {
        try {
            const client = require('prom-client');
            const metric = client.register.getSingleMetric(name);

            if (!metric) {
                logger.warn(`Attempted to use histogram that doesn't exist: ${name}`);
                return;
            }

            metric.observe(data, observeNumber);
        } catch (err: any) {
            logger.warn(`Failed to observe histogram for ${name}`);
            logger.warn(err.stack);
        }
    }

    public static async GetMetrics() {
        const client = require('prom-client');
        return await client.register.metrics();
    }

    registerCounters() {
        const counters = this.getCountersToRegister();

        const namedCounters = counters.map((counter) => {
            return {
                ...counter,
                name: `${this.getModuleName()}_${counter.name}`,
            };
        });

        namedCounters.forEach(this.createCounter);
    }

    registerHistograms() {
        const histograms = this.getHistogramsToRegister();

        const namedHistograms = histograms.map((histogram) => {
            return {
                ...histogram,
                name: `${this.getModuleName()}_${histogram.name}`,
            };
        });

        namedHistograms.forEach(this.createHistogram);
    }

    protected registerClientSideEvent(eventName, data) {
        const fullName = `bff_${this.getModuleName()}_${eventName}`;

        axios
            .post('/bff-api/metrics/counter', {
                eventName: fullName,
                data,
            })
            .catch((err) => {
                // Catch and print error, but don't stop execution.
                // We don't want to cause errors because we couldn't record some metrics.
                logger.warn(err);
            });
    }

    protected registerServerSideEvent(eventName, data) {
        if (typeof window !== 'undefined') {
            logger.info(
                'Attempted to register a serverside event on the client.  Ignoring request.'
            );
            return;
        }

        const fullName = `bff_${this.getModuleName()}_${eventName}`;

        MetricsRegistry.IncrementValidCounter(fullName, data);
    }

    protected registerClientSideHistogramEvent(eventName, observeNumber, data) {
        const fullName = `bff_${this.getModuleName()}_${eventName}`;

        axios
            .post('/bff-api/metrics/histogram', {
                eventName: fullName,
                observeNumber,
                data,
            })
            .catch((err) => {
                // Catch and print error, but don't stop execution.
                // We don't want to cause errors because we couldn't record some metrics.
                logger.warn(err);
            });
    }

    protected registerServerSideHistogramEvent(eventName, observeNumber, data) {
        if (typeof window !== 'undefined') {
            logger.info(
                'Attempted to register a serverside histogram event on the client.  Ignoring request.'
            );
            return;
        }

        const fullName = `bff_${this.getModuleName()}_${eventName}`;

        MetricsRegistry.ObserveHistogram(fullName, observeNumber, data);
    }

    private createCounter({ name, labelNames }) {
        const client = require('prom-client');
        const metric = client.register.getSingleMetric(name);

        if (metric) {
            return metric;
        }

        const newCounter = new client.Counter({
            name: `bff_${name}`,
            help: `bff_${name}_help`,
            labelNames,
        });

        return newCounter;
    }

    private createHistogram({ name, labelNames, buckets }) {
        const client = require('prom-client');
        const metric = client.register.getSingleMetric(name);

        if (metric) {
            return metric;
        }

        const newHistogram = new client.Histogram({
            name: `bff_${name}`,
            help: `bff_${name}_help`,
            labelNames,
            buckets,
        });

        return newHistogram;
    }
}
