import { Device, L1Category, L2Category, PageType } from '@gumtree/shared/src/types/client-data';
import { ScreenSize } from '@gumtree/shared/src/util/breakpoints-service';
import buildSlotConfig, { SlotConfigParams, vipPages, resultsPages } from './build-slot-config';
import { AdUnit, Mapping, SlotConfig, SlotName } from './slot-configs';

export type PageConfigParams = {
    pageType: PageType;
    resultSize: number;
    device: Device;
    l1Category: L1Category;
    l2Category: L2Category;
};

export type PageConfig = {
    mapping: Mapping[];
    adUnit: AdUnit;
    slotId: SlotName;
    targeting: {
        pos: string;
    };
    startWithCollapsedSlot?: {
        pageType: PageType;
        screenSizes: ScreenSize[];
    }[];
}[];

const getAdUnitPageType = (pageType: PageType) => {
    switch (pageType) {
        case 'Homepage':
            return 'home';
        case 'VIP':
        case 'pVIP':
        case 'eVIP':
            return 'vip';
        case 'ResultsSearch':
            return 'srp';
        case 'ResultsBrowse':
            return 'srp';
        case 'R2SEmailSuccess':
            return 'reply';
        case 'LandingPageCars':
            return 'home';
        case 'UserStatic':
            return 'profile';
        case 'ResultsSeller':
            return 'profile';
        case 'LandingPageCategory':
            return 'srp';
        case 'ManageAds':
            return 'manageads';
        case 'ManageAdsPro':
            return 'manageads-pro';
        case 'PaymentSuccess':
            return 'payment';
        case 'MyMessages':
            return 'messages';
        case 'VipGallery':
            return 'vGallery';
        case 'Article':
            return 'article';
        case 'UserProfile':
            return 'profile';
        case 'Error_404':
            return 'profile';
        case 'Error_500':
            return 'profile';
        case 'PostAdCategorySelect':
        case 'PostAdCreate':
        case 'PostAdEdit':
        case 'PostAdRepost':
            return 'profile';
        default:
            return (function (_: string): never {
                throw new Error("Didn't expect to get here");
            })(pageType);
    }
};

const buildPageConfig = ({
    pageType,
    resultSize,
    device,
    l1Category,
}: PageConfigParams): PageConfig => {
    const slotConfigParams: SlotConfigParams = {
        page: pageType,
        resultSize,
        device,
        l1Category,
    };

    const slotConfigs = buildSlotConfig(slotConfigParams);
    const pagesWithL1CategoryInAdUnit: PageType[] = [
        ...vipPages,
        ...resultsPages,
        'R2SEmailSuccess',
        'LandingPageCars',
    ];
    return (slotConfigs.filter((_) => _ !== undefined) as Exclude<SlotConfig, undefined>[]).map(
        (slotConfig) => {
            return {
                mapping: slotConfig.mapping,
                adUnit: Object.fromEntries(
                    Object.entries(slotConfig.unitPos).map(([screenSize, val]) => [
                        screenSize as ScreenSize,
                        `/5144/${device}/${getAdUnitPageType(pageType)}/${val}${
                            pagesWithL1CategoryInAdUnit.includes(slotConfigParams.page)
                                ? `/${l1Category}`
                                : ''
                        }`,
                    ])
                ) as AdUnit,
                slotId: slotConfig.divId,
                targeting: {
                    pos: slotConfig.pos,
                },
                ...(slotConfig.startWithCollapsedSlot && {
                    startWithCollapsedSlot: slotConfig.startWithCollapsedSlot,
                }),
            };
        }
    );
};

export default buildPageConfig;
