import type { WindowWithGoogleTag } from '@gumtree/shared/src/types/window';

export const addKeyValuePair = ({
    key,
    value,
    googletag,
}: {
    key: string;
    value: string;
    googletag: WindowWithGoogleTag['googletag'];
}): void => {
    if (!googletag || !key || !value) {
        return;
    }

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting(key, value);
    });
};

export default (key: string, value: string) => {
    addKeyValuePair({
        key,
        value,
        // @ts-ignore
        googletag: window.googletag,
    });
};
