import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '@gumtree/ui-library';
import validate, { ErrorType } from '@gumtree/shell/src/login/validate';
import Icon from '@gumtree/ui-library/src/icon/icon';
import { postData } from '@gumtree/shell/src/login/post-data';
import { setLoginBeginCookie, trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import {
    FormContainer,
    FormTitle,
    PasswordContainer,
    ForgottenPasswordLink,
    ErrorBox,
    FormPara,
} from './register-modal.style';
import logger from '../logger';
import ForgottenPasswordFormModal from './forgotten-password-form-modal';
import { showForgotPasswordModal, LoginModalType } from '../reducers/common/login-modal';
import { ShellState, ShellUseDispatch } from '../reducers/common';

const LoginFormModal = ({ type, redirectUrl }) => {
    const formRef = React.useRef<HTMLFormElement>(null);
    const dispatch = useDispatch() as ShellUseDispatch;

    const [values, setValues] = useState({} as any);
    const [errors, setErrors] = useState({} as ErrorType);
    const [isLoading, setIsLoading] = useState(false);
    const [submitErrors, setSubmitErrors] = useState({} as ErrorType);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const { showForgotPassword, showResetText } = useSelector(selectReduxState, shallowEqual);

    const { username, password } = values;
    const areValuesEmpty = !username || !password;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setValues((values) => ({ ...values, [event.target.id]: event.target.value }));
    };

    const handleShowPassword = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleBlur = (e) => {
        const id = e.target.id;
        const newErrors = validate(values, id, true);

        // Remove undefined errors
        const updatedErrors = { ...errors, [id]: newErrors[id] };
        Object.keys(updatedErrors).forEach((key) => {
            if (updatedErrors[key] === undefined) {
                delete updatedErrors[key];
            }
        });

        setErrors(updatedErrors);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();

        const url = window.location.pathname;
        const urlParts = url.split('/');
        const adId = urlParts[urlParts.length - 1];

        const errors = validate(values, 'login', true);

        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }

        const formData = {
            username: values.username,
            password: values.password,
            legacy: 'false',
            client_id: '',
            scope: '',
            state: '',
            redirect_uri: '',
            response_type: '',
        };

        postData(formData, setIsLoading, '/bff-api/login/via-form')
            .then(async (data) => {
                if (data?.location) {
                    setLoginBeginCookie('LoginBegin');

                    if (type === LoginModalType.SELL) {
                        window.location.assign('/postad/category');
                    } else if (type === LoginModalType.FAVOURITE) {
                        window.location.assign(decodeURIComponent(redirectUrl));
                    } else if (redirectUrl) {
                        window.location.assign(redirectUrl);
                    } else if (type === LoginModalType.EMAIL) {
                        window.location.assign(`/reply/${adId}`);
                    } else {
                        window.location.reload();
                    }
                } else {
                    setIsLoading(false);
                    setSubmitErrors({
                        password: data?.password[0],
                        username: data?.username[0],
                    });
                    trackGA4Event<GA4.UserLoginEvent>({
                        event: 'user_login',
                        formName: 'user login',
                        formStep: 'user login',
                        formValidation: 'failure',
                        userAccountType: 'email',
                    });
                }
            })
            .catch((e) => {
                logger.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <FormContainer>
            {showForgotPassword ? (
                <ForgottenPasswordFormModal />
            ) : (
                <>
                    <FormTitle data-q="form-title">
                        {showResetText ? 'Your password is reset!' : 'Log in'}
                    </FormTitle>
                    <FormPara>You can now login to your account using your new password.</FormPara>
                    {Object.keys(submitErrors).length !== 0 && (
                        <ErrorBox>
                            <Icon type="info-outline" />
                            <p>
                                {submitErrors.username === 'Your username or password is incorrect'
                                    ? 'Incorrect email address or password. Check your details and try again.'
                                    : submitErrors.username}
                            </p>
                        </ErrorBox>
                    )}
                    <form
                        ref={formRef}
                        className="form"
                        name="registration-form"
                        noValidate
                        method="POST"
                        action="/bff-api/login/via-form"
                    >
                        <Input
                            name="username"
                            id="username"
                            altLabel="username"
                            label="Email address"
                            autoComplete="off"
                            required
                            type="email"
                            hideClear
                            value={username || ''}
                            onChange={handleInputChange}
                            onBlur={(e) => handleBlur(e)}
                            errorMessages={errors.username}
                        />
                        <PasswordContainer isPasswordVisible={isPasswordVisible}>
                            <Input
                                name="form.password"
                                id="password"
                                label="Password"
                                altLabel="password"
                                autoComplete="off"
                                required
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={password || ''}
                                onChange={handleInputChange}
                                errorMessages={errors.password}
                                hideClear
                                revealValueFunction={handleShowPassword}
                                isPasswordVisible={isPasswordVisible}
                            />
                        </PasswordContainer>
                        <input type="hidden" name="legacy" value="false" />
                        <ForgottenPasswordLink
                            data-q="forgotPassword"
                            type="button"
                            onClick={() => dispatch(showForgotPasswordModal({ value: true }))}
                        >
                            Forgot password?
                        </ForgottenPasswordLink>
                        <Button
                            disabled={Object.keys(errors).length > 0 || areValuesEmpty}
                            type="submit"
                            display="primary"
                            label="Continue"
                            fullWidth
                            isLoading={isLoading}
                            loadingText=""
                            onClick={handleSubmit}
                        />
                    </form>
                </>
            )}
        </FormContainer>
    );
};

function selectReduxState({ loginModal: { showForgotPassword, showResetText } }: ShellState) {
    return {
        showForgotPassword,
        showResetText,
    };
}

export default LoginFormModal;
