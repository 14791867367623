import styled from '@emotion/styled';
import { gutterSizes } from '../base/theme';

const shouldForwardProp = (p: string) => !['includeLeftGutter'].includes(p);

export const StyledSpinner = styled("svg", { shouldForwardProp })<{
    includeLeftGutter?: boolean;
}>`
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 30px;
    height: 30px;
    margin-top: -${gutterSizes.small};
    ${({ includeLeftGutter }) => includeLeftGutter && `margin-left: 20px;`}

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

export const Circle = styled.circle`
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
`;

