import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';

export const canShowTextAds = (
    pageType: PageType | undefined,
    l1Category: L1Category | undefined
) => {
    return (
        (pageType === 'ResultsBrowse' || pageType === 'ResultsSearch') &&
        (l1Category === 'for-sale' || l1Category === 'toplevel')
    );
};

export type TextAdConditions = {
    pageType: PageType | undefined;
    l1Category: L1Category | undefined;
    bingVsAdSenseMobileVariant: string | undefined;
    bingVsAdSenseDesktopVariant: string | undefined;
    device: Device | undefined;
    currentPage: number | undefined;
};

export const whichTextAdType = ({
    pageType,
    l1Category,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    device,
    currentPage,
}: TextAdConditions): 'ADSENSE' | 'BING' | undefined => {
    if (canShowTextAds(pageType, l1Category) && typeof currentPage === 'number') {
        if (device === 'mobile') {
            return (bingVsAdSenseMobileVariant === 'B' && currentPage > 1) ||
                (bingVsAdSenseMobileVariant === 'C' && currentPage === 1)
                ? 'ADSENSE'
                : 'BING';
        } else if (device && ['tablet', 'desktop'].includes(device)) {
            return (bingVsAdSenseDesktopVariant === 'B' && currentPage > 1) ||
                (bingVsAdSenseDesktopVariant === 'C' && currentPage === 1)
                ? 'ADSENSE'
                : 'BING';
        }
    }

    return undefined;
};

export const createTracking = ({
    pageType,
    l1Category,
    currentPage,
    device,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    numberOfResults,
}: TextAdConditions & {
    numberOfResults: number;
}) => {
    const isZeroResults = numberOfResults === 0;

    const trackingPageType =
        pageType === 'ResultsBrowse'
            ? `${isZeroResults ? 'Z' : ''}BRP`
            : pageType === 'ResultsSearch'
            ? `${isZeroResults ? 'Z' : ''}SRP`
            : 'unknown-page-type';

    const trackingDevice =
        device === 'mobile'
            ? 'mobile'
            : device && ['tablet', 'desktop'].includes(device)
            ? 'desktop'
            : 'unknown-device';

    const trackingGtb16Variant =
        device === 'mobile' ? bingVsAdSenseMobileVariant : bingVsAdSenseDesktopVariant;
    const trackingGtb16 =
        trackingGtb16Variant && ['A', 'B', 'C'].includes(trackingGtb16Variant)
            ? `_GTB-16-${trackingGtb16Variant}`
            : '_GTB-16-DEFAULT';

    const trackingPageNumber =
        currentPage && Number.isInteger(currentPage) && currentPage > 1
            ? 'page2'
            : currentPage === 1
            ? 'page1'
            : 'page-invalid';

    return `web_${trackingDevice}_${trackingPageType}_${l1Category}_${trackingPageNumber}${trackingGtb16}`;
};
