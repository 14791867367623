import type { PageType } from '@gumtree/shared/src/types/client-data';

type SlotId = 'slot12' | 'slot15';

export const determineSlots = ({
    googlePpcVisit,
    pageType,
}: {
    googlePpcVisit: boolean;
    pageType: PageType;
}): SlotId[] => {
    if (['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        return [...(!googlePpcVisit ? ['slot12' as SlotId] : []), 'slot15'];
    }

    return [];
};
