import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';

import {
    fetchKeywordSuggestions,
    fetchLocationSuggestions,
    KeywordSuggestion,
} from './search-bar-service';
import * as recentSearchesService from './recent-service/recent-searches-service';
import * as recentLocationsService from './recent-service/recent-locations-service';
import { ShellState, ShellUseDispatch } from '../../reducers/common';

export const toggleSearchModal = () => ({
    type: 'TOGGLE_SEARCH_MODAL',
});

export const setKeyword = (keyword: string) => ({
    type: 'SET_SEARCH_BAR_KEYWORD',
    keyword,
});

export const setInitialKeyword = (keyword: string) => ({
    type: 'SET_INITIAL_SEARCH_BAR_KEYWORD',
    keyword,
});

export const setKeywordSuggestions = (
    suggestions: KeywordSuggestion[],
    useRecentHistory = false
) => ({
    type: 'SET_SEARCH_BAR_KEYWORD_SUGGESTIONS',
    suggestions,
    useRecentHistory,
});

export const setKeywordSuggestionsLatency = (suggestionsLatency: number) => ({
    type: 'SET_SEARCH_BAR_KEYWORD_SUGGESTIONS_LATENCY',
    suggestionsLatency,
});

export const setLocationSuggestions = (
    suggestions: { name: string }[],
    useRecentHistory = false
) => {
    return {
        type: 'SET_SEARCH_BAR_LOCATION_SUGGESTIONS',
        suggestions,
        useRecentHistory,
    };
};

export const selectKeywordSuggestion =
    (
        suggestion: { name: string; category?: string; categoryDisplayName?: string },
        useRecentHistory: boolean
    ) =>
    (dispatch: ShellUseDispatch) => {
        if (useRecentHistory) {
            trackV2('KeywordHistorySelected');
        } else {
            trackV2('KeywordSuggestionSelect');
        }
        if (suggestion.category && suggestion.categoryDisplayName) {
            dispatch({
                type: 'SET_SEARCH_BAR_CATEGORY',
                category: {
                    label: suggestion.categoryDisplayName,
                    value: suggestion.category,
                },
            });
        }
        dispatch({
            type: 'SELECT_SEARCH_BAR_KEYWORD_SUGGESTION',
            suggestion,
        });
    };

export const noSuggestions = () => ({
    type: 'NO_KEYWORD_SUGGESTIONS',
});

export const removeSingleSearchFromHistory = (text: string) => (dispatch) => {
    trackGA4Event<GA4.RecentSearchCleared>({
        event: 'recent_search_cleared',
        searchTerm: text,
    });
    trackV2('KeywordHistoryCleared');

    recentSearchesService.removeSingleFromHistory(text);
    const suggestions = recentSearchesService.load();
    dispatch(setKeywordSuggestions(suggestions as KeywordSuggestion[], true));
};

export const removeSingleLocationFromHistory = (text: string) => (dispatch) => {
    trackV2('LocationHistoryCleared');

    recentLocationsService.removeSingleFromHistory(text);
    const suggestions = recentLocationsService.load();
    dispatch(setLocationSuggestions(suggestions, true));
};

export const clearAllSearchesFromHistory = () => (dispatch) => {
    trackGA4Event<GA4.RecentSearchClearAll>({ event: 'recent_search_clear_all' });
    trackV2('KeywordHistoryClearAll');

    recentSearchesService.clearAllFromHistory();
    dispatch(setKeywordSuggestions([], true));
};

export const clearAllLocationsFromHistory = () => (dispatch) => {
    trackV2('LocationHistoryClearAll');

    recentLocationsService.clearAllFromHistory();
    dispatch(setLocationSuggestions([], true));
};

export const loadRecentKeywordHistory = () => (dispatch: ShellUseDispatch) => {
    const suggestions = recentSearchesService.load();
    dispatch(setKeywordSuggestions(suggestions as KeywordSuggestion[], true));
};

let hasTrackedKeywordSuggestionShown = false;

export const setKeywordAndUpdateSuggestions = (value: string) => {
    return (dispatch: ShellUseDispatch) => {
        dispatch(setKeyword(value));
        if (value.length === 0) {
            loadRecentKeywordHistory();
        } else if (value.length < 2) {
            dispatch(setKeywordSuggestions([]));
        } else {
            const startFetchTime = performance.now();
            fetchKeywordSuggestions(value).then((data = []) => {
                const fetchTime = performance.now() - startFetchTime;

                dispatch(setKeywordSuggestionsLatency(fetchTime));

                const nonDominantCategoryProps = {
                    categoryDisplayName: 'All Categories',
                    category: 'all',
                    searchOptionsExactMatch: true,
                };

                if (!data.length) {
                    dispatch(
                        setKeywordSuggestions([
                            {
                                name: value,
                                highlight: value,
                                ...nonDominantCategoryProps,
                            },
                        ])
                    );
                } else {
                    const [suggestion, ...rest]: KeywordSuggestion[] = data;

                    if (!hasTrackedKeywordSuggestionShown) {
                        trackV2('KeywordSuggestionShown');
                        trackGA4Event<GA4.RecentSearchSuggestionsShown>({
                            event: 'recent_search_suggestions_shown',
                        });
                        hasTrackedKeywordSuggestionShown = true;
                    }

                    dispatch(
                        setKeywordSuggestions([
                            {
                                ...suggestion,
                                ...nonDominantCategoryProps,
                            } as KeywordSuggestion,
                            ...rest,
                        ])
                    );
                }
            });
        }
    };
};

export const setLocation = (location: string) => ({
    type: 'SET_SEARCH_BAR_LOCATION',
    location,
});

export const setInitialLocation = (location: string) => ({
    type: 'SET_INITIAL_SEARCH_BAR_LOCATION',
    location,
});

export const setG5SParams = (params: { busId: number; timeStamp: string; sign: string }) => ({
    type: 'SET_G5S_PARAMS',
    params,
});

export const setCategorySearch = (searchCategory: number) => ({
    type: 'SET_SEARCH_CATEGORY_ID',
    searchCategory,
});

export const selectLocationSuggestion =
    (suggestion: { name: string }, useRecentHistory: boolean) => (dispatch: ShellUseDispatch) => {
        if (useRecentHistory) {
            trackV2('LocationHistorySelected');
        } else {
            trackV2('LocationSuggestionSelect');
        }
        dispatch({
            type: 'SELECT_SEARCH_BAR_LOCATION_SUGGESTION',
            suggestion,
        });
    };

export const loadRecentLocationHistory = () => (dispatch: ShellUseDispatch) => {
    const suggestions = recentLocationsService.load();
    dispatch(setLocationSuggestions(suggestions, true));
};

let hasTrackedLocationSuggestionShown = false;

export const setLocationAndUpdateSuggestions = (value: string) => {
    return (dispatch: ShellUseDispatch) => {
        dispatch(setLocation(value));
        if (value.length === 0) {
            dispatch(loadRecentLocationHistory());
        } else if (value.length < 2) {
            dispatch(setLocationSuggestions([]));
        } else {
            fetchLocationSuggestions(value).then((suggestions) => {
                if (suggestions.length && !hasTrackedLocationSuggestionShown) {
                    trackV2('LocationSuggestionShown');
                    hasTrackedLocationSuggestionShown = true;
                }
                dispatch(setLocationSuggestions(suggestions));
            });
        }
    };
};

export const trackClickSearchBar =
    (termOrLocation: 'term' | 'location', optionsCount: number) =>
    (dispatch: ShellUseDispatch, getState: () => ShellState) => {
        const {
            searchBar: { userInteraction },
        } = getState();

        if (!userInteraction) {
            trackGA4Event<GA4.ClickSearchBar>({
                event: 'click_search_bar',
                search: {
                    fieldFirstClickedOn: termOrLocation,
                    recentHistoryShown: optionsCount > 0,
                },
            });
        }

        dispatch({ type: 'USER_HAS_INTERACTED_WITH_SEARCH_BAR' });
    };

export const hideSearchBar = () => ({
    type: 'HIDE_SEARCH_BAR',
});
