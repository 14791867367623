import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';
import {
    browseFooter,
    carsHomeBanner,
    carsHomeLeaderBoard,
    carsHomeMpu,
    carsHomeStickyBanner,
    fBanner,
    floatingFooter,
    galleryBanner,
    galleryMPU,
    headerBanner,
    homeBanner,
    homeSideAd,
    integratedListing,
    integratedMpu,
    manageAdsLeaderboard,
    mpu,
    mpu2,
    partnershipBottom,
    partnershipWidget,
    partnershipWidget1,
    pixel,
    premiumNative1,
    premiumNative2,
    rSkyB,
    rSkyB2,
    rSkyT,
    rSkyT2,
    SlotConfig,
    tBanner,
    textLink,
    textLinkBase,
    topTakeover,
    vipBanner,
    vipMiddleDesktop,
    vipMiddleMobile,
} from './slot-configs';

export type SlotConfigParams = {
    page: PageType;
    resultSize: number;
    device: Device | undefined;
    l1Category: L1Category;
};

export const resultsPages: PageType[] = ['ResultsSearch', 'ResultsBrowse'];
export const vipPages: PageType[] = ['VIP', 'eVIP', 'pVIP'];

export const buildSlotConfig = ({
    resultSize,
    l1Category,
    device,
    page,
}: SlotConfigParams): SlotConfig[] => {
    const hasZeroResults = resultSize === 0;
    const hasLargeResults = resultSize > 14;
    const hasSmallResults = resultSize > 0 && resultSize <= 14;

    const showPremiumNative1 = (
        [
            'cars-vans-motorbikes',
            'business-services',
            'flats-houses',
            'community',
            'jobs',
            'pets',
        ] as L1Category[]
    ).includes(l1Category);

    const showPremiumNative2 = (['jobs'] as L1Category[]).includes(l1Category);

    if (resultsPages.includes(page)) {
        const premiumNativeSlots: SlotConfig[] = [
            ...(showPremiumNative1 ? [premiumNative1] : []),
            ...(showPremiumNative2 ? [premiumNative2] : []),
        ];

        if (device === 'mobile') {
            if (hasZeroResults) {
                return [floatingFooter(page), pixel];
            } else if (hasSmallResults) {
                return [
                    floatingFooter(page),
                    tBanner(page),
                    integratedMpu,
                    pixel,
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    floatingFooter(page),
                    tBanner(page),
                    integratedMpu,
                    integratedListing,
                    pixel,
                    ...premiumNativeSlots,
                ];
            }
        }
        if (device === 'tablet') {
            if (hasZeroResults) {
                return [tBanner(page), pixel, ...premiumNativeSlots];
            } else if (hasSmallResults) {
                return [
                    tBanner(page),
                    integratedMpu,
                    integratedListing,
                    pixel,
                    rSkyT,
                    rSkyB,
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    tBanner(page),
                    integratedMpu,
                    integratedListing,
                    pixel,
                    rSkyT,
                    rSkyB,
                    ...premiumNativeSlots,
                ];
            }
        }
        if (device === 'desktop') {
            if (hasZeroResults) {
                return [pixel, tBanner(page), rSkyT, ...premiumNativeSlots];
            } else if (hasSmallResults) {
                return [
                    integratedMpu,
                    integratedListing,
                    pixel,
                    tBanner(page),
                    rSkyT,
                    rSkyT2,
                    rSkyB,
                    rSkyB2,
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    integratedMpu,
                    integratedListing,
                    pixel,
                    tBanner(page),
                    rSkyT,
                    rSkyT2,
                    rSkyB,
                    rSkyB2,
                    ...premiumNativeSlots,
                ];
            }
        }
    }

    if (vipPages.includes(page)) {
        const base = [
            partnershipWidget,
            partnershipWidget1,
            partnershipBottom,
            galleryBanner,
            galleryMPU,
            pixel,
        ];

        if (device === 'mobile') {
            return [...base, floatingFooter(page), textLinkBase(page), vipMiddleMobile];
        }
        if (device === 'tablet') {
            return [
                ...base,
                vipBanner,
                textLink(page, showPremiumNative1, showPremiumNative2),
                mpu(page),
                fBanner(page),

                vipMiddleDesktop,
            ];
        }
        if (device === 'desktop') {
            return [
                ...base,
                vipBanner,
                textLink(page, showPremiumNative1, showPremiumNative2),
                mpu(page),
                vipMiddleDesktop,
            ];
        }
    }

    if (page === 'Homepage') {
        return [topTakeover, homeSideAd, homeBanner, pixel];
    }

    if (page === 'VipGallery') {
        return [galleryBanner];
    }

    if (page === 'Article') {
        return [tBanner(page), mpu(page), fBanner(page)];
    }

    if (page === 'ResultsSeller' || page === 'UserStatic') {
        return [mpu(page)];
    }

    if (page === 'LandingPageCategory') {
        return [browseFooter];
    }

    if (['Error_404', 'Error_500'].includes(page)) {
        return [mpu(page)];
    }

    if (page === 'R2SEmailSuccess') {
        if (device === 'mobile') {
            return [];
        }
        return [headerBanner];
    }

    if (page === 'ManageAds' || page === 'ManageAdsPro') {
        return [manageAdsLeaderboard];
    }

    if (page === 'PaymentSuccess') {
        return [manageAdsLeaderboard, mpu2];
    }

    if (page === 'LandingPageCars') {
        return [carsHomeBanner, carsHomeLeaderBoard, carsHomeMpu, carsHomeStickyBanner];
    }

    return [];
};

export default buildSlotConfig;
