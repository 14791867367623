import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, TypeAhead } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';

import { searchFieldAriaLabel, searchFieldPlaceholderText } from '../constants';
import type { ShellUseDispatch } from '../../../reducers/common';
import { trackClickSearchBar } from '../search-bar-actions';

interface KeywordSearchFieldProps {
    clearAllFromHistory: () => void;
    useRecentHistory: boolean;
    setKeywordAndUpdateSuggestions: (value: string) => void;
    loadRecentKeywordHistory: () => void;
    query?: string;
    removeSingleFromHistory: (text: string) => void;
    selectSuggestion: (
        option: {
            name: string;
            category?: string;
            categoryDisplayName?: string;
        },
        useRecentHistory: boolean
    ) => void;
    outsideFocus?: boolean;
    suggestions: { name: string; category?: string; categoryDisplayName?: string }[];
}

const KeywordSearchField: React.FC<KeywordSearchFieldProps> = ({
    clearAllFromHistory,
    useRecentHistory,
    setKeywordAndUpdateSuggestions,
    loadRecentKeywordHistory,
    query = '',
    removeSingleFromHistory,
    selectSuggestion,
    suggestions = [],
    outsideFocus,
}) => {
    const dispatch = useDispatch() as ShellUseDispatch;
    const [hasTrackedKeywordHistoryShown, setHasTrackedKeywordHistoryShown] = useState(false);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setKeywordAndUpdateSuggestions(value);
    };

    useEffect(() => {
        loadRecentKeywordHistory();
    }, [loadRecentKeywordHistory]);

    const handleOpen = useCallback(() => {
        if (useRecentHistory && !hasTrackedKeywordHistoryShown) {
            trackV2('KeywordHistoryShown');
            trackGA4Event<GA4.RecentSearchHistoryShown>({ event: 'recent_search_history_shown' });
            setHasTrackedKeywordHistoryShown(true);
        }
    }, [useRecentHistory, hasTrackedKeywordHistoryShown]);

    return (
        <TypeAhead
            inputProps={{
                'aria-label': searchFieldAriaLabel,
                onInput: handleInput,
                placeholder: searchFieldPlaceholderText,
                maxLength: 50,
                ...qaAttribute('search-keyword-field'),
            }}
            icon={<Icon type="magnifying-glass" size="medium" />}
            id="q"
            limit={10}
            outsideFocus={outsideFocus}
            name="q"
            onFocus={() => {
                dispatch(trackClickSearchBar('term', suggestions.length));
            }}
            onOpen={handleOpen}
            onSelectOption={(value) => {
                selectSuggestion(value, useRecentHistory);
            }}
            options={suggestions}
            removeSingleFromHistory={removeSingleFromHistory}
            clearAllFromHistory={clearAllFromHistory}
            useRecentHistory={useRecentHistory}
            defaultValue={query}
            isTypeAheadOptionsIconsVisible
            typeAheadIcon={
                useRecentHistory ? (
                    <Icon type="clock" size="medium" />
                ) : (
                    <Icon type="magnifying-glass" size="medium" />
                )
            }
            titleOfRecentHistory={`Recent Search${suggestions.length === 1 ? '' : 'es'}`}
            showClearAllButton
        />
    );
};

export default KeywordSearchField;
