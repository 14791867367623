import React, { useState, useEffect, useRef } from 'react';

import NoImage from './no-image.svg';
import { getContainerStyle, getImageStyle } from './image-service';
import { Figure } from './image.style';

/**
 * @param {Props} props
 */
const Image = (props) => {
    const container = useRef<HTMLElement>(null);
    const imageEl = useRef<HTMLImageElement>(null);
    const { alt, className, datasrc, height, imageClasses, src, width, shouldSetContainerStyles } =
        props;

    const [imageStyle, setImageStyle] = useState({});

    const setImageStyles = () => {
        // @ts-ignore
        setImageStyle(getImageStyle(container, imageEl));
    };

    useEffect(() => {
        const image = imageEl.current;
        if (image && image.complete && !('object-fit' in image.style)) {
            setImageStyles();
        }
    });

    const imageSrc = datasrc || src;

    return (
        <Figure
            {...(shouldSetContainerStyles && {
                style: getContainerStyle(height, width),
            })}
            noSrc={!src}
            className={className}
            ref={container}
        >
            {datasrc || src ? (
                <img
                    suppressHydrationWarning
                    className={imageClasses}
                    alt={alt}
                    onLoad={setImageStyles}
                    ref={imageEl}
                    style={imageStyle}
                    {...(src ? { src: imageSrc } : {})}
                    {...(datasrc ? { 'data-src': imageSrc } : {})}
                />
            ) : (
                <NoImage />
            )}
        </Figure>
    );
};

Image.defaultProps = {
    alt: null,
    className: null,
    datasrc: undefined,
    height: null,
    imageClasses: undefined,
    shouldSetContainerStyles: true,
    src: null,
    width: null,
};

/**
 * @typedef Props
 * @property {string | null} alt
 * @property {string | null} className
 * @property {string} [datasrc]
 * @property {number | null} height
 * @property {string} [imageClasses]
 * @property {boolean} [shouldSetContainerStyles]
 * @property {string | null} src
 * @property {number | null} width
 */

export default Image;
