import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import transformDataIntoAdModels, { hasProductAd } from '../common/transform-data-into-ad-models';

export const sendGa4Impression = ({
    adModels,
}: {
    adModels: ReturnType<typeof transformDataIntoAdModels>;
}) => {
    const advertisingTextAdIsFilled = adModels.length > 0;
    let advertisingTextAdType: GA4.TextAdImpression['advertisingTextAdType'];
    if (!advertisingTextAdIsFilled) {
        advertisingTextAdType = 'unfilled';
    } else {
        advertisingTextAdType = hasProductAd(adModels) ? 'product' : 'text';
    }
    trackGA4Event<GA4.TextAdImpression>({
        event: 'advertising_text_ad_impression',
        advertisingTextAdProvider: 'Bing',
        advertisingTextAdType,
        advertisingTextAdIsFilled,
        advertisingTextAdSlotHeight: undefined,
    });
};
