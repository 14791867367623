import type { SearchBarKeywordState } from './reducers/keyword-reducer';
import type { SearchBarLocationState } from './reducers/location-reducer';

export class TrackingUtil {
    static getPopulatedBy(
        state: SearchBarKeywordState | SearchBarLocationState | undefined,
        isManualInput = false
    ):
        | GA4.SubmitListingSearchEvent['search']['keywordPopulatedBy']
        | GA4.SubmitListingSearchEvent['search']['locationPopulatedBy'] {
        if (!isManualInput && state?.selectedSuggestion) {
            return state.useRecentHistory ? 'history' : 'suggested';
        }
        return state?.value === state?.initialValue ? 'unchanged' : 'input';
    }

    static getKeywordSuggestions(
        keywordPopulatedBy: GA4.SubmitListingSearchEvent['search']['keywordPopulatedBy'],
        keyword: SearchBarKeywordState | undefined
    ): string[] {
        return keywordPopulatedBy === 'suggested'
            ? keyword?.suggestions?.map((suggestion) => suggestion.name) || []
            : [];
    }

    static getKeywordSuggestionsLatency(
        keywordPopulatedBy: GA4.SubmitListingSearchEvent['search']['keywordPopulatedBy'],
        keyword: SearchBarKeywordState | undefined
    ): number | undefined {
        return keywordPopulatedBy === 'suggested' && keyword?.suggestionsLatency
            ? Math.floor(keyword.suggestionsLatency)
            : undefined;
    }

    static getLatencyCategory(latency: number): string {
        if (latency <= 20) {
            return 'up_to_20ms';
        } else if (latency <= 30) {
            return 'up_to_30ms';
        } else if (latency <= 40) {
            return 'up_to_40ms';
        } else if (latency <= 50) {
            return 'up_to_50ms';
        } else if (latency <= 100) {
            return 'up_to_100ms';
        } else if (latency <= 200) {
            return 'up_to_200ms';
        } else if (latency <= 500) {
            return 'up_to_500ms';
        } else if (latency <= 1000) {
            return 'up_to_1000ms';
        } else if (latency <= 2000) {
            return 'up_to_2000ms';
        } else {
            return 'more_than_2001ms';
        }
    }
}
