import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { isDesktop } from '../../../common/domain/device';
import type { DataLayer } from '../../loader';

const reportTileClick = (params: {
    slotId: string;
    deviceSize: string;
    index: string;
    pageType: string;
    dataLayer?: DataLayer;
}) => {
    if (!params.dataLayer) {
        return;
    }

    if (!isDesktop(params.deviceSize)) {
        return;
    }

    const location = params.slotId === 'slot12' ? 'top' : 'bottom';
    const eventLabel = `${location}${params.index}`;

    trackV2('BingAdsClickOut', eventLabel, {
        eventCategory: params.pageType,
        eventAction: 'BingAdsClickOut',
    });
};

export default reportTileClick;
