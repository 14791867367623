import UaParser from 'ua-parser-js';

const getComputedStyle = (element) => {
    return window.getComputedStyle(element);
};

const getComputedPropertyValue = (element, property) => {
    return getComputedStyle(element).getPropertyValue(property);
};

const getElementHeight = (element) => {
    return element.getBoundingClientRect().height;
};

const addClass = (element, className) => {
    if (element.classList) {
        element.classList.add(className);
    }
};

const removeClass = (element, className) => {
    if (element.classList) {
        element.classList.remove(className);
    }
};

const addEventListener = (element, eventName, callback) => {
    element.addEventListener(eventName, callback);
};

const removeEventListener = (element, eventName, callback) => {
    element.removeEventListener(eventName, callback);
};

const runOnDOMContentLoaded = (callback) => {
    if (document.readyState === 'complete') {
        callback();
    } else {
        addEventListener(document, 'DOMContentLoaded', callback);
    }
};

const returnHTML = (html) => {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.children;
};

const requestAnimationFrame = (callback) => {
    window.requestAnimationFrame(callback);
};

const redirectToUrl = (url) => {
    window.location.assign(url);
};

const reloadPage = () => {
    window.location.reload();
};

const getPathName = () => {
    return typeof window !== 'undefined' ? window.location.pathname : '';
};

const getHashName = () => {
    return typeof window !== 'undefined' ? window.location.hash : '';
};

const getCookie = () => document.cookie;

const setCookie = (cookie) => {
    document.cookie = cookie;
};

/*
 * copied logic from legacy util:
 * mobile-web-static-assets/src/main/resources/rwd/framework/js/utils/utils.js
 *
 * it opens a new popup window and triggers focus on it
 */
const openPopup = ({ url, title = '', popupWidth = 470, popupHeight = 580 }) => {
    // Fixes dual-screen position                               Most browsers           Firefox
    // @ts-ignore
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    // @ts-ignore
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
    let width;
    let height;

    if (window.innerWidth) {
        width = window.innerWidth;
    } else {
        if (document.documentElement.clientWidth) {
            width = document.documentElement.clientWidth;
        } else {
            width = screen.width;
        }
    }

    if (window.innerHeight) {
        height = window.innerHeight;
    } else {
        if (document.documentElement.clientHeight) {
            height = document.documentElement.clientHeight;
        } else {
            height = screen.height;
        }
    }

    const left = width / 2 - popupWidth / 2 + dualScreenLeft;
    const top = height / 3 - popupHeight / 3 + dualScreenTop;

    const newWindow = window.open(
        url,
        title,
        `scrollbars=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`
    );

    // Puts focus on the newWindow
    // @ts-ignore
    if (window.focus) {
        // @ts-ignore
        newWindow.focus();
    }
};

const humanBrowsers = new Set([
    'Chrome',
    'Edge',
    'Opera Mini',
    'Opera Mobile',
    'Opera',
    'Opera Mini (iOS)',
    'Opera Webkit',
    'Baidu',
    'Kindle',
    'Maxthon',
    'IEMobile',
    'Vivaldi',
    'Puffin',
    'Brave',
    'QQBrowserLite',
    'QQ',
    'DuckDuckGo',
    'Weibo',
    'UCBrowser',
    'WeChat',
    'IE',
    'Yandex',
    'Smart Lenovo Browser',
    'Opera Touch',
    'Dolphin',
    'Opera Coast',
    'MIUI Browser',
    'Firefox (iOS)',
    '360 Browser',
    'Oculus Browser',
    'Sailfish Browser',
    'Huawei Browser',
    'Vivo Browser',
    'Samsung Internet',
    'Comodo Dragon',
    'Sogou Explorer',
    'Sogou Mobile',
    'Electron',
    'Tesla Browser',
    'QQBrowser',
    'LinkedIn App',
    'Facebook App',
    'Klarna Browser',
    'Line Browser',
    'Alipay Browser',
    'Chromium',
    'Instagram',
    'Snapchat',
    'TikTok',
    'Android Browser',
    'Tizen Browser',
    'Nokia Browser',
    'Mobile Safari',
    'Safari',
    'Netscape',
    'Firefox Reality',
    'Firefox',
    'Mozilla',
    'NetSurf',
    'Mosaic',
]);

// `isHumanBrowser` as opposed to `isBot`
export const isHumanBrowser = (userAgent: string | undefined): boolean => {
    if (!userAgent) {
        return false;
    }
    const parser = new UaParser(userAgent);
    const currentBrowser = parser?.getBrowser()?.name;
    return humanBrowsers.has(currentBrowser);
};

export {
    addClass,
    removeClass,
    addEventListener,
    getComputedPropertyValue,
    getElementHeight,
    openPopup,
    redirectToUrl,
    reloadPage,
    removeEventListener,
    requestAnimationFrame,
    returnHTML,
    runOnDOMContentLoaded,
    getCookie,
    setCookie,
    getPathName,
    getHashName,
};
