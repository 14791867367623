import { getClientData, getLegacyGumData } from './data-service';
import { ExperimentName, Experiments, ExperimentVariants } from '../types/client-data';

const getExperiments = (name: ExperimentName): ExperimentVariants | undefined => {
    if ((window as any).clientData) {
        return getClientData(`experiments.${name}`);
    } else {
        return getLegacyGumData(`domain.ab.${name}`);
    }
};

export const convertExperimentValue = (value: boolean | string | undefined): ExperimentVariants => {
    if (typeof value === 'string') {
        if (['ON', 'OFF'].includes(value)) {
            return value === 'OFF' ? 'A' : 'B';
        } else {
            return value as ExperimentVariants;
        }
    }

    return !value ? 'A' : 'B';
};

export const convertExperimentsToStringArray = (
    experiments: Experiments
): `${'GTALL' | 'GTP' | 'GTF' | 'GTC'}-${string}-${ExperimentVariants}`[] =>
    Object.entries(experiments).map(
        ([key, value]) =>
            `${key}-${value}` as `${
                | 'GTALL'
                | 'GTP'
                | 'GTF'
                | 'GTC'}-${string}-${ExperimentVariants}`
    );

export { getExperiments };
