import React from 'react';
import classnames from 'classnames';

import './form-element.scss';

const getClassName = (className, inline, type) =>
    classnames(
        {
            'form-element': true,
            [`form-element--${type}`]: true,
            'form-element--inline': inline,
        },
        className
    );

interface Props extends React.PropsWithChildren {
    className?: string;
    inline?: boolean;
    type: 'select' | 'input';
    children?: React.ReactNode;
}

const FormElement: React.FC<Props> = ({
    children,
    className = '',
    inline = false,
    type,
    ...props
}: Props) => {
    const TagName = !inline ? 'div' : 'span';

    return (
        <TagName className={getClassName(className, inline, type)} {...props}>
            {children}
        </TagName>
    );
};

export default FormElement;
