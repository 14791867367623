import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, TypeAhead } from '@gumtree/ui-library';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';

import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { locationFieldAriaLabel, locationFieldPlaceholderText } from '../constants';
import { trackClickSearchBar } from '../search-bar-actions';
import type { ShellUseDispatch } from '../../../reducers/common';

interface LocationFieldProps {
    setLocationAndUpdateSuggestions(value: string): void;
    loadRecentLocationHistory(): void;
    query: string;
    selectSuggestion(option: { name: string }, useRecentHistory: boolean): void;
    suggestions: any[];
    useRecentHistory: boolean;
    removeSingleFromHistory: (text: string) => void;
    clearAllFromHistory: () => void;
}

const LocationField: React.FC<LocationFieldProps> = ({
    setLocationAndUpdateSuggestions,
    loadRecentLocationHistory,
    query,
    selectSuggestion,
    suggestions = [],
    useRecentHistory,
    removeSingleFromHistory,
    clearAllFromHistory,
}) => {
    const dispatch = useDispatch() as ShellUseDispatch;
    const [hasTrackedLocationHistoryShown, setHasTrackedLocationHistoryShown] = useState(false);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLocationAndUpdateSuggestions(value);
    };

    useEffect(() => {
        loadRecentLocationHistory();
    }, []);

    return (
        <TypeAhead
            inputProps={{
                'aria-label': locationFieldAriaLabel,
                onInput: handleInput,
                placeholder: locationFieldPlaceholderText,
                ...qaAttribute('search-location-field'),
            }}
            icon={<Icon type="beacon" size="medium" />}
            id="header-search-location"
            limit={10}
            name="search_location"
            onFocus={() => {
                dispatch(trackClickSearchBar('location', suggestions.length));
            }}
            onOpen={() => {
                if (useRecentHistory && !hasTrackedLocationHistoryShown) {
                    trackV2('LocationHistoryShown');
                    setHasTrackedLocationHistoryShown(true);
                }
            }}
            onSelectOption={(option) => {
                selectSuggestion(option, useRecentHistory);
            }}
            options={suggestions}
            removeSingleFromHistory={removeSingleFromHistory}
            clearAllFromHistory={clearAllFromHistory}
            useRecentHistory={useRecentHistory}
            defaultValue={query}
            typeAheadIcon={
                useRecentHistory ? (
                    <Icon type="clock" size="medium" />
                ) : (
                    <Icon type="beacon" size="medium" />
                )
            }
            titleOfRecentHistory={`Recent Location${suggestions.length === 1 ? '' : 's'}`}
            showClearAllButton={false}
        />
    );
};

export default LocationField;
