import type { WindowWithAdSense, WindowWithClientData } from '@gumtree/shared/src/types/window';

import { createTracking, TextAdConditions } from '../util/text-ads-util';
import { sendGa4Impression } from './send-ga4-impression';
import { getChannelId } from './channel-lookup';

const desktopStyleId = '8681725653';
const mobileStyleId = '4591008367';

export const loadAdSenseAds = ({
    pageType,
    l1Category,
    currentPage,
    device,
    bingVsAdSenseMobileVariant,
    bingVsAdSenseDesktopVariant,
    numberOfResults,
    isAdSenseLive,
    query,
    window,
}: TextAdConditions & {
    window: WindowWithAdSense & WindowWithClientData;
    numberOfResults: number;
    isAdSenseLive: boolean;
    query: string;
}) => {
    const channel =
        getChannelId(
            createTracking({
                pageType,
                l1Category,
                currentPage,
                device,
                bingVsAdSenseMobileVariant,
                bingVsAdSenseDesktopVariant,
                numberOfResults,
            })
        ) ?? '';

    const pageOptions = {
        pubId: 'partner-pub-8646360968412482',
        query,
        styleId: device === 'mobile' ? mobileStyleId : desktopStyleId,
        linkTarget: '_blank',
        adtest: isAdSenseLive ? 'off' : 'on',
        channel,
        relatedSearches: 0,
        adLoadedCallback: function (containerName: 'slot12' | 'slot15', adsLoaded: boolean) {
            sendGa4Impression({
                containerName,
                adsLoaded,
                window,
            });
        },
    };

    const slot12Id = 'slot12';
    const slot12Config = {
        container: slot12Id,
        maxTop: 1,
    };
    const hasSlot12 = Boolean(window.document.getElementById(slot12Id));

    const slot15Id = 'slot15';
    const slot15Config = {
        container: slot15Id,
        number: 1,
    };
    const hasSlot15 = Boolean(window.document.getElementById(slot15Id));

    const slotConfigs = [
        ...(hasSlot12 ? [slot12Config] : []),
        ...(hasSlot15 ? [slot15Config] : []),
    ];

    window._googCsa('ads', pageOptions, ...slotConfigs);
};
