import { PageType } from '@gumtree/shared/src/types/client-data';
import { ScreenSize } from '@gumtree/shared/src/util/breakpoints-service';
import { resultsPages, vipPages } from './build-slot-config';

export type Mapping = {
    screenSize: ScreenSize[];
    screenHeight?: number;
    adSize: (number[] | 'fluid')[];
};

export type AdUnit = {
    [key in ScreenSize]: string;
};

export const DisplayAdSlotDivNames = {
    tBanner: 'tBanner',
    pixel: 'pixel',
    rSkyT: 'rSkyT',
    rSkyT2: 'rSkyT2',
    rSkyB: 'rSkyB',
    rSkyB2: 'rSkyB2',
    carsHomeBanner: 'carsHomeBanner',
    carsHomeMpu: 'carsHomeMpu',
    carsHomeStickyBanner: 'carsHomeStickyBanner',
    carsHomeLeaderBoard: 'carsHomeLeaderBoard',
    homeBanner: 'homeBanner',
    homeSideAd: 'homeSideAd',
    topTakeover: 'top_takeover',
    leaderboard: 'leaderboard',
    mpu: 'mpu',
    mpu2: 'mpu2',
    headerBanner: 'headerBanner',
    partnershipBottom: 'partnershipBottom',
    vipMiddleMobile: 'vipMiddleMobile',
    vipMiddleDesktop: 'vipMiddleDesktop',
    textLink: 'textLink',
    textLinkBase: 'textLinkBase',
    partnershipWidget: 'partnershipWidget',
    partnershipWidget1: 'partnershipWidget1',
    vipBanner: 'vipBanner',
    fBanner: 'fBanner',
    floatingFooter: 'floatingFooter',
    premiumNative1: 'premiumNative1',
    integratedMpu: 'integratedMpu',
    integratedListing: 'integratedListing',
    galleryBanner: 'galleryBanner',
    galleryMPU: 'galleryMPU',
    browseFooter: 'browseFooter',
    premiumNative2: 'premiumNative2',
    baseMpu: 'baseMpu',
    top_takeover: 'top_takeover',
} as const;

export type SlotName = typeof DisplayAdSlotDivNames[keyof typeof DisplayAdSlotDivNames];

export type SlotConfig =
    | {
          pos: string;
          unitPos: AdUnit;
          divId: SlotName;
          mapping: Mapping[];
          startWithCollapsedSlot?: {
              pageType: PageType;
              screenSizes: ScreenSize[];
          }[];
      }
    | undefined;

export const floatingFooter = (page: PageType): SlotConfig => ({
    pos: 'floating',
    unitPos: {
        xxl: 'floating',
        xl: 'floating',
        l: 'floating',
        m: 'floating',
        s: 'floating',
        xs: 'floating',
    },
    divId: DisplayAdSlotDivNames.floatingFooter,
    mapping: [
        {
            screenSize: ['xs', 's'],
            adSize: vipPages.includes(page)
                ? [
                      [320, 50],
                      [1, 1],
                  ]
                : [[320, 50]],
        },
    ],
});
export const textLinkBase = (page: PageType): SlotConfig => ({
    pos: 'bottom',
    unitPos: {
        xxl: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
        xl: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
        l: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
        m: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
        s: resultsPages.includes(page) ? 'middle2' : 'bottom',
        xs: resultsPages.includes(page) ? 'middle2' : 'bottom',
    },
    divId: DisplayAdSlotDivNames.textLinkBase,
    mapping: [
        {
            screenSize: ['xs', 's'],
            adSize: [
                [330, 75],
                ...(resultsPages.includes(page) ? [[300, 250]] : []),
                ...(vipPages.includes(page) ? [[1, 1]] : []),
                'fluid',
            ],
        },
        {
            screenSize: ['m', 'l', 'xl', 'xxl'],
            adSize: [...(vipPages.includes(page) ? [[1, 1]] : []), 'fluid'],
        },
    ],
});
export const integratedMpu: SlotConfig = {
    pos: 'middle1',
    unitPos: {
        xxl: 'middle1',
        xl: 'middle1',
        l: 'middle1',
        m: 'middle1',
        s: 'middle1',
        xs: 'middle1',
    },
    divId: DisplayAdSlotDivNames.integratedMpu,
    mapping: [
        {
            screenSize: ['xs', 's'],
            adSize: [[1, 1], [320, 200], [300, 250], [320, 50], 'fluid'],
        },
        {
            screenSize: ['m', 'l', 'xl', 'xxl'],
            adSize: [[300, 250], [728, 90], 'fluid'],
        },
    ],
};
export const tBanner = (page: PageType): SlotConfig => ({
    pos: 'top',
    unitPos: {
        xxl: 'top',
        xl: 'top',
        l: 'top',
        m: 'top',
        s: 'top',
        xs: 'top',
    },
    divId: DisplayAdSlotDivNames.tBanner,
    mapping: [
        {
            screenSize: ['xs', 's'],
            adSize: [
                [2000, 255],
                ...(resultsPages.includes(page)
                    ? [[414, 155], [414, 160], 'fluid' as 'fluid']
                    : []),
            ],
        },
        {
            screenSize: ['m'],
            adSize: [
                [728, 90],
                [2000, 255],
            ],
        },
        {
            screenSize: ['l', 'xl', 'xxl'],
            adSize: [
                [728, 90],
                [970, 90],
                [970, 250],
                [2000, 255],
            ],
        },
    ],
    startWithCollapsedSlot: [
        { pageType: 'ResultsBrowse', screenSizes: ['xs', 's'] },
        { pageType: 'ResultsSearch', screenSizes: ['xs', 's'] },
    ],
});
export const pixel: SlotConfig = {
    pos: 'pixel',
    unitPos: {
        xxl: 'pixel',
        xl: 'pixel',
        l: 'pixel',
        m: 'pixel',
        s: 'pixel',
        xs: 'pixel',
    },
    divId: DisplayAdSlotDivNames.pixel,
    mapping: [
        {
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
            adSize: [[1, 1]],
        },
    ],
};
export const rSkyT: SlotConfig = {
    divId: DisplayAdSlotDivNames.rSkyT,
    mapping: [
        {
            adSize: [
                [160, 600],
                [1, 1],
            ],
            screenSize: ['xl'],
        },
        {
            adSize: [
                [300, 250],
                [300, 600],
                [300, 1050],
                [1, 1],
            ],
            screenSize: ['xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'topRight',
        m: 'topRight',
        s: 'topRight',
        xl: 'topRight',
        xs: 'topRight',
        xxl: 'topRight',
    },
};
export const rSkyT2: SlotConfig = {
    divId: DisplayAdSlotDivNames.rSkyT2,
    mapping: [
        {
            adSize: [
                [300, 250],
                [300, 600],
                [1, 1],
            ],
            screenSize: ['xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'topRight2',
        m: 'topRight2',
        s: 'topRight2',
        xl: 'topRight2',
        xs: 'topRight2',
        xxl: 'topRight2',
    },
};
export const rSkyB: SlotConfig = {
    divId: DisplayAdSlotDivNames.rSkyB,
    mapping: [
        {
            adSize: [
                [160, 600],
                [1, 1],
            ],
            screenSize: ['xl'],
        },
        {
            adSize: [
                [300, 250],
                [300, 600],
                [300, 1050],
                [1, 1],
            ],
            screenSize: ['xxl'],
        },
    ],
    pos: 'floating-bottom',
    unitPos: {
        l: 'bottomRight',
        m: 'bottomRight',
        s: 'bottomRight',
        xl: 'bottomRight',
        xs: 'bottomRight',
        xxl: 'bottomRight',
    },
};
export const rSkyB2: SlotConfig = {
    divId: DisplayAdSlotDivNames.rSkyB2,
    mapping: [
        {
            adSize: [
                [300, 250],
                [300, 600],
                [1, 1],
            ],
            screenSize: ['xxl'],
        },
    ],
    pos: 'floating-bottom',
    unitPos: {
        l: 'bottomRight2',
        m: 'bottomRight2',
        s: 'bottomRight2',
        xl: 'bottomRight2',
        xs: 'bottomRight2',
        xxl: 'bottomRight2',
    },
};
export const mpu2: SlotConfig = {
    divId: DisplayAdSlotDivNames.mpu2,
    mapping: [
        {
            screenSize: ['xl', 'xxl'],
            adSize: [[300, 250]],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'topRight',
        m: 'topRight',
        s: 'topRight',
        xl: 'topRight',
        xs: 'topRight',
        xxl: 'topRight',
    },
};

export const manageAdsLeaderboard: SlotConfig = {
    divId: DisplayAdSlotDivNames.leaderboard,
    mapping: [
        {
            adSize: [[320, 50]],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [[728, 90]],
            screenSize: ['m', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'top',
        m: 'top',
        s: 'top',
        xl: 'top',
        xs: 'top',
        xxl: 'top',
    },
};

export const carsHomeMpu: SlotConfig = {
    divId: DisplayAdSlotDivNames.carsHomeMpu,
    mapping: [
        {
            adSize: [[300, 250]],
            screenSize: ['xs', 's', 'm'],
        },
    ],
    pos: 'middle2',
    unitPos: {
        l: 'middle1',
        m: 'middle2',
        s: 'middle2',
        xl: 'middle1',
        xs: 'middle2',
        xxl: 'middle1',
    },
};

export const carsHomeLeaderBoard: SlotConfig = {
    divId: DisplayAdSlotDivNames.carsHomeLeaderBoard,
    mapping: [
        {
            adSize: [[728, 90]],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'top',
        m: 'middle1',
        s: 'middle1',
        xl: 'top',
        xs: 'middle1',
        xxl: 'top',
    },
};

export const carsHomeStickyBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.carsHomeStickyBanner,
    mapping: [
        {
            adSize: [[320, 50]],
            screenSize: ['xs', 's'],
        },
    ],
    pos: 'floating',
    unitPos: {
        l: 'floating',
        m: 'floating',
        s: 'floating',
        xl: 'floating',
        xs: 'floating',
        xxl: 'floating',
    },
};

export const carsHomeBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.carsHomeBanner,
    mapping: [
        {
            adSize: [[320, 50]],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [[728, 90]],
            screenSize: ['m', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'middle1',
    unitPos: {
        l: 'middle1',
        m: 'middle1',
        s: 'middle1',
        xl: 'middle1',
        xs: 'middle1',
        xxl: 'middle1',
    },
};

export const headerBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.headerBanner,
    mapping: [
        {
            adSize: [[728, 90]],
            screenSize: ['m'],
        },
        {
            adSize: [
                [728, 90],
                [970, 90],
                [970, 250],
            ],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'top',
        m: 'top',
        s: 'top',
        xl: 'top',
        xs: 'top',
        xxl: 'top',
    },
};

export const browseFooter: SlotConfig = {
    divId: DisplayAdSlotDivNames.browseFooter,
    mapping: [
        {
            adSize: [
                [320, 100],
                [320, 50],
            ],
            screenSize: ['xs', 's'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'bottom',
        m: 'bottom',
        s: 'bottom',
        xl: 'bottom',
        xs: 'bottom',
        xxl: 'bottom',
    },
};

export const homeBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.homeBanner,
    mapping: [
        {
            adSize: [[320, 50]],
            screenSize: ['xs', 's'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'middle1',
        m: 'middle1',
        s: 'middle1',
        xl: 'middle1',
        xs: 'middle1',
        xxl: 'middle1',
    },
};

export const homeSideAd: SlotConfig = {
    divId: DisplayAdSlotDivNames.homeSideAd,
    mapping: [
        {
            adSize: [[160, 600]],
            screenSize: ['m'],
        },
        {
            adSize: [
                [300, 250],
                [300, 600],
                [300, 1050],
            ],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'topRight',
        m: 'topRight',
        s: 'topRight',
        xl: 'topRight',
        xs: 'topRight',
        xxl: 'topRight',
    },
};

export const fBanner = (page: PageType): SlotConfig => ({
    divId: DisplayAdSlotDivNames.fBanner,
    mapping: [
        {
            adSize: [[320, 50], [300, 250], ...(vipPages.includes(page) ? [[1, 1]] : [])],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [[728, 90], ...(vipPages.includes(page) ? [[1, 1]] : [])],
            screenSize: ['m'],
        },
        {
            adSize: [
                [728, 90],
                [970, 90],
                [970, 250],
                ...(vipPages.includes(page) ? [[1, 1]] : []),
            ],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'bottom',
    unitPos: {
        l: 'bottom',
        m: 'bottom',
        s: 'bottom',
        xl: 'bottom',
        xs: 'bottom',
        xxl: 'bottom',
    },
});

export const vipMiddleDesktop: SlotConfig = {
    divId: DisplayAdSlotDivNames.vipMiddleDesktop,
    mapping: [
        {
            adSize: [
                [300, 250],
                [1, 1],
            ],
            screenSize: ['xl', 'xxl'],
        },
    ],
    pos: 'vip-middle-desktop',
    unitPos: {
        l: 'bottomRight',
        m: 'bottomRight',
        s: 'bottomRight',
        xl: 'bottomRight',
        xs: 'bottomRight',
        xxl: 'bottomRight',
    },
};

export const topTakeover: SlotConfig = {
    divId: DisplayAdSlotDivNames.top_takeover,
    mapping: [
        {
            adSize: [[2000, 255]],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [
                [728, 90],
                [2000, 255],
            ],
            screenSize: ['m'],
        },
        {
            adSize: [
                [970, 250],
                [2000, 255],
            ],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'top',
        m: 'top',
        s: 'top',
        xl: 'top',
        xs: 'top',
        xxl: 'top',
    },
};

export const galleryBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.galleryBanner,
    mapping: [
        {
            adSize: [
                [320, 50],
                [1, 1],
            ],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [
                [728, 90],
                [1, 1],
            ],
            screenSize: ['m', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'gallery',
    unitPos: {
        l: 'gallery',
        m: 'gallery',
        s: 'gallery',
        xl: 'gallery',
        xs: 'gallery',
        xxl: 'gallery',
    },
};

export const vipMiddleMobile: SlotConfig = {
    divId: DisplayAdSlotDivNames.vipMiddleMobile,
    mapping: [
        {
            adSize: [
                [300, 250],
                [1, 1],
            ],
            screenSize: ['xs', 's'],
        },
    ],
    pos: 'vip-middle-mobile',
    unitPos: {
        l: 'middle1',
        m: 'middle1',
        s: 'middle1',
        xl: 'middle1',
        xs: 'middle1',
        xxl: 'middle1',
    },
};

export const integratedListing: SlotConfig = {
    divId: DisplayAdSlotDivNames.integratedListing,
    mapping: [
        {
            adSize: [
                [320, 50],
                [300, 250],
                [320, 100],
            ],
            screenSize: ['xs', 's'],
        },
        {
            adSize: [[728, 200], [300, 250], [728, 90], 'fluid'],
            screenSize: ['m', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'middle2',
    unitPos: {
        l: 'middle2',
        m: 'middle2',
        s: 'middle2',
        xl: 'middle2',
        xs: 'middle2',
        xxl: 'middle2',
    },
};

export const partnershipBottom: SlotConfig = {
    divId: DisplayAdSlotDivNames.partnershipBottom,
    mapping: [
        {
            adSize: ['fluid'],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'partnershipBottom',
        m: 'partnershipBottom',
        s: 'partnershipBottom',
        xl: 'partnershipBottom',
        xs: 'partnershipBottom',
        xxl: 'partnershipBottom',
    },
};

export const partnershipWidget: SlotConfig = {
    divId: DisplayAdSlotDivNames.partnershipWidget,
    mapping: [
        {
            adSize: ['fluid'],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'partnershipWidget',
    unitPos: {
        l: 'partnershipWidget',
        m: 'partnershipWidget',
        s: 'partnershipWidget',
        xl: 'partnershipWidget',
        xs: 'partnershipWidget',
        xxl: 'partnershipWidget',
    },
};

export const partnershipWidget1: SlotConfig = {
    divId: DisplayAdSlotDivNames.partnershipWidget1,
    mapping: [
        {
            adSize: ['fluid'],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'partnershipWidget1',
    unitPos: {
        l: 'partnershipWidget1',
        m: 'partnershipWidget1',
        s: 'partnershipWidget1',
        xl: 'partnershipWidget1',
        xs: 'partnershipWidget1',
        xxl: 'partnershipWidget1',
    },
};

export const mpu = (page: PageType): SlotConfig => {
    if (vipPages.includes(page)) {
        return {
            divId: DisplayAdSlotDivNames.mpu,
            mapping: [
                {
                    adSize: [
                        [300, 250],
                        [1, 1],
                    ],
                    screenSize: ['xs', 's'],
                },
                {
                    adSize: [
                        [300, 250],
                        [300, 600],
                        [1, 1],
                    ],
                    screenSize: ['m', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: 'topRight',
                m: 'topRight',
                s: 'topRight',
                xl: 'topRight',
                xs: 'topRight',
                xxl: 'topRight',
            },
        };
    } else if (page === 'R2SEmailSuccess') {
        return {
            divId: DisplayAdSlotDivNames.mpu,
            mapping: [
                {
                    adSize: [
                        [320, 50],
                        [300, 250],
                    ],
                    screenSize: ['xs', 's', 'm'],
                },
                {
                    adSize: [
                        [300, 250],
                        [640, 480],
                    ],
                    screenSize: ['l', 'xl', 'xxl'],
                },
            ],
            pos: 'bottom',
            unitPos: {
                l: 'middle1',
                m: 'middle1',
                s: 'middle1',
                xl: 'middle1',
                xs: 'middle1',
                xxl: 'middle1',
            },
        };
    } else {
        return {
            divId: DisplayAdSlotDivNames.mpu,
            mapping: [
                {
                    adSize: [[300, 250]],
                    screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: 'topRight',
                m: 'bottom',
                s: 'bottom',
                xl: 'topRight',
                xs: 'bottom',
                xxl: 'topRight',
            },
        };
    }
};

export const premiumNative1: SlotConfig = {
    divId: DisplayAdSlotDivNames.premiumNative1,
    mapping: [
        {
            adSize: ['fluid'],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'premiumNative1',
    unitPos: {
        l: 'premiumNative1',
        m: 'premiumNative1',
        s: 'premiumNative1',
        xl: 'premiumNative1',
        xs: 'premiumNative1',
        xxl: 'premiumNative1',
    },
    startWithCollapsedSlot: [
        { pageType: 'ResultsBrowse', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
        { pageType: 'ResultsSearch', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
    ],
};

export const premiumNative2: SlotConfig = {
    divId: DisplayAdSlotDivNames.premiumNative2,
    mapping: [
        {
            adSize: ['fluid'],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'premiumNative2',
    unitPos: {
        l: 'premiumNative2',
        m: 'premiumNative2',
        s: 'premiumNative2',
        xl: 'premiumNative2',
        xs: 'premiumNative2',
        xxl: 'premiumNative2',
    },
    startWithCollapsedSlot: [
        { pageType: 'ResultsBrowse', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
        { pageType: 'ResultsSearch', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
    ],
};

export const textLink = (
    pageType: PageType,
    showPremiumNative1: boolean,
    showPremiumNative2: boolean
): SlotConfig => {
    if (!pageType) {
        return undefined;
    }

    if (resultsPages.includes(pageType) && (showPremiumNative1 || showPremiumNative2)) {
        return undefined;
    } else {
        return {
            divId: DisplayAdSlotDivNames.textLink,
            mapping: [
                {
                    adSize: [[330, 75], 'fluid', ...(vipPages.includes(pageType) ? [[1, 1]] : [])],
                    screenSize: [
                        'xs',
                        's',
                        ...(vipPages.includes(pageType) ? ['m' as ScreenSize] : []),
                    ],
                },
                {
                    adSize: ['fluid', ...(vipPages.includes(pageType) ? [[1, 1]] : [])],
                    screenSize: [
                        ...(resultsPages.includes(pageType) ? ['m' as ScreenSize] : []),
                        'l',
                        'xl',
                        'xxl',
                    ],
                },
            ],
            pos: 'top',
            unitPos: {
                l: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                m: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                s: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xl: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xs: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xxl: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
            },
        };
    }
};

export const vipBanner: SlotConfig = {
    divId: DisplayAdSlotDivNames.vipBanner,
    mapping: [
        {
            adSize: [
                [728, 90],
                [1, 1],
            ],
            screenSize: ['m'],
        },
        {
            adSize: [
                [728, 90],
                [970, 90],
                [970, 250],
                [1, 1],
            ],
            screenSize: ['l', 'xl', 'xxl'],
        },
    ],
    pos: 'top',
    unitPos: {
        l: 'top',
        m: 'top',
        s: 'top',
        xl: 'top',
        xs: 'top',
        xxl: 'top',
    },
};

export const galleryMPU: SlotConfig = {
    divId: DisplayAdSlotDivNames.galleryMPU,
    mapping: [
        {
            adSize: [[300, 250]],
            screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
        },
    ],
    pos: 'galleryMPU',
    unitPos: {
        l: 'galleryMPU',
        m: 'galleryMPU',
        s: 'galleryMPU',
        xl: 'galleryMPU',
        xs: 'galleryMPU',
        xxl: 'galleryMPU',
    },
};
