export enum ExperimentVariant {
    VARIANT_A = 'A',
    VARIANT_B = 'B',
    VARIANT_C = 'C',
    VARIANT_D = 'D',
}

export enum ExperimentNames {
    PHONE_AUTH = 'GTALL-27464',
    CORECHAT_READ_BFF = 'GTCC-921-CORECHAT-READ',
    MANAGE_ADS_BFF = 'GTP-2827',
}

export const GrowthBookFeature = {
    REVIEW_FEATURE_FLAG: 'GTC-2380',
    UNREAD_FEATURE_FLAG: 'GTC-2379',
    FORGOTTEN_PASSWORD_FLAG: 'GTC-2643',
    G5S: 'G5S-17',
    OPEN_VIP_IN_NEW_TAB: 'GTB-13',
    LOCATION_PROMPT: 'GTF-1533',
    SRP_CHIPS_SOURCE: 'G5S-57',
    LOGOUT_TO_BFF: 'GTCON-20',
    BING_VS_ADSENSE_MOBILE: 'GTB-14-mWeb',
    BING_VS_ADSENSE_DESKTOP: 'GTB-14-Desktop', // includes Tablet
    EXPIRED_ADS_BANNER: 'GTS-74',
    GALLERY_HAS_MPU_AD: 'GTB-26',
} as const;
