import React from 'react';
import classnames from 'classnames';

import GumtreeLogo from './gumtree-logo.svg';
import GumtreeLogoText from './gumtree-logo-text.svg';

import './logo.scss';
import '../utils/hide-visually.scss';

const Logo: React.FC<Props> = ({
    linkTo = null,
    small = false,
    title = 'Gumtree',
    vertical = false,
    withText = true,
    ...props
}) => {
    const className = classnames({
        logo: true,
        'logo--small': small,
        'logo--vertical': vertical,
    });

    const TagName = !linkTo ? 'div' : 'a';

    return (
        <TagName className={className} href={linkTo || undefined} title={title} {...props}>
            <div className="logo-image">
                <GumtreeLogo />
            </div>
            {withText && (
                <div className="logo-text">
                    <GumtreeLogoText />
                    <span className="hide-visually">{title}</span>
                </div>
            )}
        </TagName>
    );
};

interface Props {
    linkTo?: string | null;
    onClick?(e: React.MouseEvent): void;
    small?: boolean;
    title?: string;
    vertical?: boolean;
    withText?: boolean;
}

export default Logo;
