import React, { useState } from 'react';
import { Button, Input } from '@gumtree/ui-library';
import validate, { ErrorType } from '@gumtree/shell/src/login/validate';
import { postData } from '@gumtree/shell/src/login/post-data';
import CheckEmailSVG from '@gumtree/ui-library/src/assets/check-email.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    CheckEmailContent,
    CheckEmailTitle,
    FormContainer,
    FormTitle,
    EmailHighlight,
    FormPara,
    PasswordContainer,
    PasswordAdviceContainer,
    PasswordAdvice,
    PasswordStrength,
} from './register-modal.style';
import logger from '../logger';
import { showForgotPasswordModal } from '../reducers/common/login-modal';
import { ShellState, ShellUseDispatch } from '../reducers/common';

const ForgottenPasswordFormModal = () => {
    const formRef = React.useRef<HTMLFormElement>(null);
    const dispatch = useDispatch() as ShellUseDispatch;
    const [values, setValues] = useState({} as any);
    const [errors, setErrors] = useState({} as ErrorType);
    const [isPasswordVisible, setIsPasswordVisible] = useState<{
        newPassword?: boolean;
        confirmedPassword?: boolean;
    }>({
        newPassword: false,
        confirmedPassword: false,
    });
    const [showChecklist, setShowChecklist] = useState<boolean>(false);
    const [passwordValidated, setPasswordValidated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEmailConf, setShowEmailConf] = useState({
        show: false,
        email: '',
    });
    const { isReset } = useSelector(selectReduxState, shallowEqual);
    const [validationState, setValidationState] = useState({
        lowerCase: { title: 'One lower case letter', validated: false },
        upperCase: { title: 'One upper case letter', validated: false },
        number: { title: 'One number', validated: false },
        specialChar: { title: 'One special character', validated: false },
        minLength: { title: 'Minimum 8 characters', validated: false },
    });

    const { username, password, confirmedPassword } = values;
    const areValuesEmpty = !username;
    const arePasswordValuesEmpty = !password || !confirmedPassword;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setValues((values) => ({
            ...values,
            [event.target.id]: event.target.value,
        }));
        event.target.name === 'form.password' && validatePassword(event.target.value);
    };

    const handleBlur = (e) => {
        const id = e.target.id;
        const newErrors = validate(values, id, true);

        // Remove undefined errors
        const updatedErrors = { ...errors, [id]: newErrors[id] };
        Object.keys(updatedErrors).forEach((key) => {
            if (updatedErrors[key] === undefined) {
                delete updatedErrors[key];
            }
        });

        setErrors(updatedErrors);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();

        const errors = validate(values, '', true);

        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }

        const formData = {
            'form.username': values.username,
        };

        postData(formData, setIsLoading, '/bff-api/reset/via-form')
            .then(async (data) => {
                if (data) {
                    !data?.withError &&
                        setShowEmailConf({
                            show: true,
                            email: data.resetPasswordFormBean.username,
                        });
                } else {
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                logger.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmitPassword = async (event: React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();

        const errors = validate(values, '', true);

        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }

        const formData = {
            'form.password': values.password,
        };

        postData(formData, setIsLoading, '/bff-api/reset/forgot-password-form')
            .then(async (data) => {
                if (data) {
                    data.location && dispatch(showForgotPasswordModal({value: false, isReset: false, showResetText: true}));
                } else {
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                logger.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function validatePassword(password: string) {
        const lowerCasePattern = /[a-z]/;
        const upperCasePattern = /[A-Z]/;
        const numberPattern = /[0-9]/;
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
        const minLengthPattern = /.{8,}/;

        const hasLowerCase = lowerCasePattern.test(password);
        const hasUpperCase = upperCasePattern.test(password);
        const hasNumber = numberPattern.test(password);
        const hasSpecialChar = specialCharPattern.test(password);
        const hasMinLength = minLengthPattern.test(password);

        setValidationState({
            lowerCase: { title: 'One lower case letter', validated: hasLowerCase },
            upperCase: { title: 'One upper case letter', validated: hasUpperCase },
            number: { title: 'One number', validated: hasNumber },
            specialChar: { title: 'One special character', validated: hasSpecialChar },
            minLength: { title: 'Minimum 8 characters', validated: hasMinLength },
        });

        setPasswordValidated(
            hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinLength
        );
    }

    const handleShowPassword = (passwordType: string) => {
        setIsPasswordVisible((prevState) => ({
            ...prevState,
            [passwordType]: !prevState[passwordType],
        }));
    };

    return (
        <FormContainer>
            {isReset ? (
                <>
                    <FormTitle>Reset your password</FormTitle>
                    <FormPara>
                        Please use a password that is at least 8 characters long and includes one
                        number, one capital letter and one special character.
                    </FormPara>
                    <form
                        ref={formRef}
                        className="form"
                        name="registration-form"
                        noValidate
                        method="POST"
                        action="/bff-api/reset/forgot-password-form"
                    >
                        <PasswordContainer isPasswordVisible={isPasswordVisible.newPassword}>
                            <Input
                                labelClassName={
                                    password?.length > 0 && !passwordValidated && !showChecklist
                                        ? 'error-label'
                                        : ''
                                }
                                name="form.password"
                                id="password"
                                label="New password"
                                altLabel="password"
                                autoComplete="off"
                                required
                                type={isPasswordVisible.newPassword ? 'text' : 'password'}
                                value={password || ''}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                onFocus={() => {
                                    setShowChecklist(true);
                                }}
                                errorMessages={errors.password}
                                hideClear
                                revealValueFunction={() => handleShowPassword('newPassword')}
                                isPasswordVisible={isPasswordVisible.newPassword}
                            />
                            <PasswordAdviceContainer>
                                {showChecklist && !passwordValidated ? (
                                    <>
                                        {Object.values(validationState).map((el) => (
                                            <PasswordAdvice
                                                data-testid={`${el.title}-${el.validated}`}
                                                isValidated={el.validated}
                                                key={el.title}
                                            >
                                                {el.title}
                                            </PasswordAdvice>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {password?.length > 0 && (
                                            <PasswordStrength isValidated={passwordValidated}>
                                                {passwordValidated
                                                    ? 'Strong password'
                                                    : 'Weak password'}
                                            </PasswordStrength>
                                        )}
                                    </>
                                )}
                            </PasswordAdviceContainer>
                        </PasswordContainer>
                        <PasswordContainer isPasswordVisible={isPasswordVisible.confirmedPassword}>
                            <Input
                                labelClassName={
                                    password?.length > 0 && !passwordValidated && !showChecklist
                                        ? 'error-label'
                                        : ''
                                }
                                name="form.password_confirmed"
                                id="confirmedPassword"
                                label="Confirm password"
                                altLabel="confirmedPassword"
                                autoComplete="off"
                                required
                                type={isPasswordVisible.confirmedPassword ? 'text' : 'password'}
                                value={confirmedPassword || ''}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                errorMessages={errors.confirmedPassword}
                                hideClear
                                revealValueFunction={() => handleShowPassword('confirmedPassword')}
                                isPasswordVisible={isPasswordVisible.confirmedPassword}
                            />
                        </PasswordContainer>
                        <Button
                            disabled={Object.keys(errors).length > 0 || arePasswordValuesEmpty}
                            type="submit"
                            display="primary"
                            data-q="submit_password_confirm"
                            label="Continue"
                            fullWidth
                            isLoading={isLoading}
                            loadingText=""
                            onClick={handleSubmitPassword}
                        />
                    </form>
                </>
            ) : showEmailConf.show ? (
                <>
                    <CheckEmailSVG />
                    <CheckEmailTitle data-q="email-sent">Email sent</CheckEmailTitle>
                    <CheckEmailContent>
                        We’ve sent a link to <EmailHighlight>{showEmailConf.email}</EmailHighlight>{' '}
                        to reset your password. If you have a Gumtree account registered, you will
                        receive an email. Please check your spam folder.
                    </CheckEmailContent>
                </>
            ) : (
                <>
                    <FormTitle>Forgot password</FormTitle>
                    <FormPara>
                        Please enter the email address you used to create your account. We will then
                        send you an email to change your password.
                    </FormPara>
                    <form
                        ref={formRef}
                        className="form"
                        name="registration-form"
                        noValidate
                        method="POST"
                        action="/bff-api/reset/via-form"
                    >
                        <Input
                            name="username"
                            id="username"
                            altLabel="username"
                            label="Email address"
                            autoComplete="off"
                            required
                            type="email"
                            hideClear
                            value={username || ''}
                            onChange={handleInputChange}
                            onBlur={(e) => handleBlur(e)}
                            errorMessages={errors.username}
                        />
                        <Button
                            disabled={Object.keys(errors).length > 0 || areValuesEmpty}
                            type="submit"
                            display="primary"
                            data-q="submit_password"
                            label="Continue"
                            fullWidth
                            isLoading={isLoading}
                            loadingText=""
                            onClick={handleSubmit}
                        />
                    </form>
                </>
            )}
        </FormContainer>
    );
};

function selectReduxState({ loginModal: { isReset } }: ShellState) {
    return {
        isReset,
    };
}

export default ForgottenPasswordFormModal;
