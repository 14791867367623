export const convertUnderscoresToDashes = (input: string | null | undefined): string => {
    if (!input) {
        return '';
    }

    return input.replace(/_/g, '-');
};

export const convertSpacesToDashes = (input: string | null | undefined): string => {
    if (input === null || input === undefined) {
        return '';
    }
    return input.replace(/ /g, '-');
};
