import { css } from "@emotion/react";
import { boxSizes, colors, fontSizes, fontWeights, gutterSizes } from "../base/theme";

export const buttonCss = (theme) => css`
    display: inline-flex;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    text-align: center;
    user-select: none;
    white-space: nowrap;

    background-color: transparent;
    border: ${boxSizes.borderWidth} solid transparent;
    border-radius: ${boxSizes.borderRadius};
    color: ${colors.bark};
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.normal};
    height: ${boxSizes.boxHeight};
    line-height: 20px;
    padding: ${gutterSizes.medium10} ${gutterSizes.medium};
    transition: background-color 0.222s ease-out;

    .icon {
        margin-right: ${gutterSizes.small};
        position: relative;
        top: 0;
        vertical-align: middle;
    }

    &:active,
    &:hover {
        color: ${colors.barkDark};
    }

    & + & {
        margin-left: ${gutterSizes.large};
    }

    &.button--dark {
        color: ${colors.white};

        &:active,
        &:hover {
            color: ${colors.bark40};
        }
    }

    &.button--GTALL-26629-primary {
        color: ${theme.palette.primary.mainContrastText};
        background-color: ${theme.palette.primary.main};
        border-color: transparent;

        &:active,
        &:hover {
            color: ${theme.palette.primary.darkContrastText};
            background-color: ${theme.palette.primary.dark};
        }
    }

    &.button--primary {
        color: ${theme.palette.primary.mainContrastText};
        background-color: ${theme.palette.primary.main};
        border-color: transparent;

        &:active,
        &:hover {
            color: ${theme.palette.primary.darkContrastText};
            background-color: ${theme.palette.primary.dark};
        }
    }

    &.button--secondary {
        color: ${theme.palette.secondary.mainContrastText};
        background-color: ${theme.palette.secondary.main};
        border-color: ${theme.palette.secondary.mainContrastText};

        &:active,
        &:hover {
            color: ${theme.palette.secondary.darkContrastText};
            background-color: ${theme.palette.secondary.dark};
        }
    }

    &.button--tertiary {
        color: ${theme.palette.secondary.mainContrastText};
        background-color: ${theme.palette.secondary.main};
        border-color: ${theme.palette.secondary.mainContrastText};

        &:active,
        &:hover {
            color: ${theme.palette.secondary.darkContrastText};
            background-color: ${theme.palette.secondary.dark};
        }
    }

    &.button--quarternary {
        color: ${theme.palette.secondary.mainContrastText};
        background: ${theme.palette.secondary.main};
        transition: background-color 222ms ease-out;
        border: 1px solid ${theme.palette.secondary.mainContrastText};

        &.selected,
        &:hover {
            background: ${theme.palette.secondary.dark};
            color: ${theme.palette.secondary.darkContrastText};
        }
    }

    &.button:disabled {
        color: ${theme.palette.disabled.mainContrastText};
        background-color: ${theme.palette.disabled.main};
        border-color: ${theme.palette.disabled.border};
    }

    &.button--full-width {
        display: block;
        width: 100%;

        & + & {
            margin-left: 0;
            margin-top: ${gutterSizes.large};
        }
    }

    &.button--only-icon {
        .icon {
            margin-right: 0;
        }

        & + & {
            margin-left: ${gutterSizes.small};
        }
    }

    &.button--bottom-label {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        font-size: ${fontSizes.small14};
        line-height: 1;

        .icon {
            margin-right: 0;
            margin-bottom: ${gutterSizes.small};
            top: 0;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.button--right-icon {
        .icon {
            margin-right: 0;
            margin-left: ${gutterSizes.small};
        }
    }

    &.button--color-green {
        color: ${colors.leaf};
    }
`;
