import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PostAdButton, Icon, Button, Form } from '@gumtree/ui-library/src';

import { trackGtmEvent } from '@gumtree/shared/src/util/gtm-tracker';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';

import {
    AlternativeText,
    BackgroundImage,
    ButtonGroup,
    Content,
    ContentWrapper,
    Header,
    Overlay,
    Title,
    Logo,
} from './index.style';
import type { ShellState } from '../../reducers/common';
import { setShowFullScreenOverlay } from '../../reducers/common/location-prompt';
import { submitSearchHandler } from '../common/event-handlers';
import LocationSearch from '../common/location-search';

export const loginButtonClickHandler = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    baseConfig: ShellState['baseConfig'],
) => {
    e.preventDefault();
    await Promise.all([
        trackGtmEvent({ action: 'HeaderLoginLink' }),
        trackGtmEvent({ action: 'HeaderLoginSelect' }),
    ]);
    window.location.assign(`${baseConfig.buyerUrl}/login`);
};

export const postAdButtonClickHandler = async (baseConfig: ShellState['baseConfig']) => {
    window.location.assign(`${baseConfig.bffUrl}/postad/category`);
};

export const FullScreenPrompt: React.FC = () => {
    const dispatch = useDispatch();
    const { baseConfig } = useSelector((state: ShellState) => ({
        baseConfig: state.baseConfig,
        experiments: state.experiments,
    }));

    const [location, setLocation] = useState('');
    const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

    useEffect(() => {
        trackGtmEvent({ action: 'LocationPromptFullScreenShown' });
    }, []);

    return (
        <Overlay>
            <Header>
                <Logo />
                <ButtonGroup>
                    <PostAdButton
                        data-testid="post-ad-button"
                        label="Sell"
                        onClick={() => {
                            postAdButtonClickHandler(baseConfig).then();
                        }}
                        bottomLabel
                    />
                    <Button
                        dark
                        icon={<Icon type="person" />}
                        label="Login/Register"
                        href={`${baseConfig.buyerUrl}/login`}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            loginButtonClickHandler(e, baseConfig).then();
                        }}
                        bottomLabel
                    />
                </ButtonGroup>
            </Header>
            <ContentWrapper>
                <Content>
                    <Form
                        onSubmit={() => {
                            submitSearchHandler(location, setIsSearchSubmitted, 'input').then();
                        }}
                    >
                        <Title>Find buyers and sellers in your community</Title>
                        <LocationSearch
                            isSearchSubmitted={isSearchSubmitted}
                            setIsSearchSubmitted={setIsSearchSubmitted}
                            location={location}
                            setLocation={setLocation}
                        />
                        <AlternativeText>
                            <span>or </span>
                            <a
                                href="/#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    trackGA4Event<GA4.ClickPopUp>({
                                        event: 'click_pop_up',
                                        locationPrompt: {
                                            popUpAction: 'continue',
                                            popUpCategory: 'location prompt',
                                        },
                                    });
                                    dispatch(setShowFullScreenOverlay(false));
                                }}
                            >
                                continue
                            </a>
                        </AlternativeText>
                    </Form>
                </Content>
                <BackgroundImage />
            </ContentWrapper>
        </Overlay>
    );
};

export default FullScreenPrompt;
