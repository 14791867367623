import { trackImmediately } from './track-event';

const QUEUE_KEY = 'permutive-next-page-queue';

const isLocalStorageUsable = (window) => {
    try {
        const testKey = '__test__';
        window.localStorage.setItem(testKey, 'test');
        window.localStorage.removeItem(testKey);
        return true;
    } catch (err) {
        console.warn('localStorage is not usable:', err);
        return false;
    }
};

const addToQueue = (window: Window, payload: object) => {
    if (!isLocalStorageUsable(window)) {
        return;
    }

    window.localStorage.setItem(QUEUE_KEY, JSON.stringify(payload));
};

const getCurrentEvent = (window: Window) => {
    if (!isLocalStorageUsable(window)) {
        return null;
    }

    const eventData = window.localStorage.getItem(QUEUE_KEY);
    return eventData ? JSON.parse(eventData) : null;
};

const clearQueue = (window) => {
    if (!isLocalStorageUsable(window)) {
        return;
    }

    window.localStorage.removeItem(QUEUE_KEY);
};

export const processPermutiveQueue = (window) => {
    const payload = getCurrentEvent(window);

    if (!payload) {
        return;
    }

    try {
        trackImmediately(window, payload);
        clearQueue(window);
    } catch (err) {
        console.error('Failed to process event:', err);
    }
};

// Ensures even if event fails to register due to user navigating away from page, then on the next page load it will retry on page load
export const queueCustomEvent = async (window: Window, payload: object) => {
    if (!isLocalStorageUsable(window)) {
        await trackImmediately(window, payload);
        return;
    }

    addToQueue(window, payload);

    await trackImmediately(window, payload);

    clearQueue(window);
};
