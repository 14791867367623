import { DataLayerBase } from '../data-layer-service';
import { parseDatalayer } from './datalayer-parser';

const generateCommonPayload = (
    dataLayerBase: DataLayerBase,
    deviceType: string,
    userLoggedIn: boolean
) => {
    const {
        pageType,
        platform,
        rootCategoryId,
        l1CategoryId,
        l2CategoryId,
        l3CategoryId,
        l4CategoryId,
        rootLocationId,
        l1LocationId,
        l2LocationId,
        l3LocationId,
        l4LocationId,
        vehicleMake,
        vehicleModel,
        transmission,
        fuelType,
        adId,
        userType,
        sellerType,
        priceAmount,
        priceCurrency,
        descriptionLength,
        imageCount,
        creationDate,
        lastPublishedDate,
        advertClickSource,
    } = parseDatalayer(dataLayerBase);

    return {
        pageType,
        platform,
        deviceSize: deviceType,
        ...(fuelType && {
            vehicle: {
                make: vehicleMake,
                model: vehicleModel,
                transmission,
                fuelType,
            },
        }),
        ...(dataLayerBase?.c && {
            category: {
                ...(rootCategoryId && {
                    l0: {
                        id: `${rootCategoryId}`,
                    },
                }),
                ...(l1CategoryId && {
                    l1: {
                        id: `${l1CategoryId}`,
                    },
                }),
                ...(l2CategoryId && {
                    l2: {
                        id: `${l2CategoryId}`,
                    },
                }),
                ...(l3CategoryId && {
                    l3: {
                        id: `${l3CategoryId}`,
                    },
                }),
                ...(l4CategoryId && {
                    l4: {
                        id: `${l4CategoryId}`,
                    },
                }),
            },
        }),
        ...(dataLayerBase?.l && {
            location: {
                ...(rootLocationId && {
                    l0: {
                        id: `${rootLocationId}`,
                    },
                }),
                ...(l1LocationId && {
                    l1: {
                        id: `${l1LocationId}`,
                    },
                }),
                ...(l2LocationId && {
                    l2: {
                        id: `${l2LocationId}`,
                    },
                }),
                ...(l3LocationId && {
                    l3: {
                        id: `${l3LocationId}`,
                    },
                }),
                ...(l4LocationId && {
                    l4: {
                        id: `${l4LocationId}`,
                    },
                }),
            },
        }),
        user: {
            loggedIn: userLoggedIn,
        },
        ...(dataLayerBase?.a?.id && {
            ad: {
                id: `${adId}`,
                user: {
                    type: userType,
                },
                ...(sellerType && { sellerType }),
                ...(dataLayerBase.a.prc && {
                    price: {
                        ...(priceAmount && {
                            amount:
                                typeof priceAmount === 'string'
                                    ? parseInt(priceAmount)
                                    : priceAmount,
                        }),
                        ...(priceCurrency && { currency: priceCurrency }),
                    },
                }),
                descriptionLength,
                imageCount,
                creationDate,
                lastPublishedDate,
                advertClickSource,
            },
        }),
    };
};

export const appendSellerType = (existingPayload, sellerType) => {
    return {
        ...existingPayload,
        ...(sellerType && { ad: { ...existingPayload.ad, ...{ sellerType } } }),
    };
};

export const generatePageViewPayload = (
    dataLayerBase: DataLayerBase,
    deviceType,
    userLoggedIn: boolean
) => generateCommonPayload(dataLayerBase, deviceType, userLoggedIn);

export const generateActionPayload = (
    dataLayerBase: DataLayerBase,
    deviceType: string,
    userLoggedIn: boolean,
    eventName: string,
    sellerType?: string
) => {
    const commonPayload = generateCommonPayload(dataLayerBase, deviceType, userLoggedIn);
    return appendSellerType({ ...commonPayload, actionName: eventName }, sellerType);
};
