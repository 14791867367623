/* eslint-disable no-console */
//@ts-check
import Cookies from 'js-cookie';

// 🔔 This file is written in such a way that it can be reused directly in legacy.

const ga4PostponedCookieName = 'GT_GA4_POSTPONED_TRACKING';

/**
 * @param  {...GA4.GA4Event} events
 */
export function postponeGA4ToNextPage(...events) {
    try {
        const existing = Cookies.get(ga4PostponedCookieName);
        let value = /** @type {GA4.GA4Event[]} */ (JSON.parse(existing || '[]'));

        if (
            !(
                Array.isArray(value) &&
                value.every((e) => e && typeof e === 'object' && 'event' in e)
            )
        ) {
            console.debug(`${'postponeGA4ToNextPage'}: expected GA4.GA4Event[]: ${existing}`);
            value = []; // ignore previous value
        }

        value.push(...events);

        // 🔔 cookie max size ≤ 4096 bytes
        Cookies.set(ga4PostponedCookieName, JSON.stringify(value), {
            domain: getSharedCookieDomain(),
            expires: (1 / 24) * (5 / 60), // 5 minutes from now
        });
    } catch (e) {
        console.debug(`${'postponeGA4ToNextPage'}: ${JSON.stringify(events)}`, e);
    }
}

// Moved to GTM
export function trackGA4FromPrevPage() {
    try {
        const existing = Cookies.get(ga4PostponedCookieName);
        const value = /** @type {GA4.GA4Event[]} */ (JSON.parse(existing || '[]'));

        if (
            !(
                Array.isArray(value) &&
                value.every((e) => e && typeof e === 'object' && 'event' in e)
            )
        ) {
            throw Error(`${'trackGA4FromPrevPage'}: expected GA4.GA4Event[]: ${existing}`);
        }

        value.forEach((event) => window.gumtreeDataLayer.push(event));
    } catch (e) {
        console.debug(`${'trackGA4FromPrevPage'}`, e);
    } finally {
        Cookies.remove(ga4PostponedCookieName, { domain: getSharedCookieDomain() });
    }
}

/**
 * - Prod: `gumtree.com` (not my.gumtree.com, www.gumtree.com)
 * - QA: `gumtree.io` (not my.staging.gumtree.io, www.staging.gumtree.io)
 */
export function getSharedCookieDomain() {
    const { hostname } = window.location;
    if (hostname.endsWith('.gumtree.com')) {
        return 'gumtree.com';
    } else if (hostname.endsWith('.gumtree.io')) {
        // treat *.staging.gumtree.io same as dev.gumtree.io e.g.
        // because of proxy-seller.thirdparty.staging.gumtree.io
        return 'gumtree.io';
    } else {
        return hostname;
    }
}
