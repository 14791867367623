import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFeatureValue } from '@growthbook/growthbook-react';
import { GrowthBookFeature } from '@gumtree/shared/src/model/experiment';
import { isHumanBrowser } from '@gumtree/shared/src/util/browser-service';
import addKeyValuePair from '@gumtree/third-party-ads/src/framework/dfp/add-key-value-pair';

import FullScreenPrompt from './full-screen';
import PopUpPrompt from './pop-up';
import { ShellState } from '../reducers/common';
import { setShowFullScreenOverlay, setShowPopUpPrompt } from '../reducers/common/location-prompt';
import {
    fullScreenPrompt,
    hasBeenShownLocationPrompt,
    popUpPrompt,
    setHasBeenShownLocationPrompt,
} from './common/persistence-util';

export const LocationPrompt: React.FC = () => {
    const dispatch = useDispatch();
    const locationPromptVariant = useFeatureValue(GrowthBookFeature.LOCATION_PROMPT, 'A');

    const {
        showFullScreenOverlay,
        showPopUpPrompt,
        isFirstTimeVisit,
        pageType,
        isUsingDefaultLocation,
        userAgent,
    } = useSelector((state: ShellState) => {
        return {
            showFullScreenOverlay: state.locationPrompt.showFullScreenOverlay,
            showPopUpPrompt: state.locationPrompt.showPopUpPrompt,
            isFirstTimeVisit: state.locationPrompt.isFirstTimeVisit,
            pageType: state.baseConfig.pageType,
            isUsingDefaultLocation:
                state.searchBar.location.value === state.searchBar.location.defaultLocation,
            userAgent: state.request.headers['user-agent'],
        };
    });

    const isCurrentBrowserHuman = isHumanBrowser(userAgent);

    useEffect(() => {
        if (
            isCurrentBrowserHuman &&
            isUsingDefaultLocation &&
            isFirstTimeVisit &&
            (pageType === 'ResultsBrowse' ||
                pageType === 'ResultsSearch' ||
                pageType === 'Homepage') &&
            locationPromptVariant === 'B' &&
            !hasBeenShownLocationPrompt(fullScreenPrompt)
        ) {
            setHasBeenShownLocationPrompt(fullScreenPrompt);
            addKeyValuePair('loc-prompt', 'full');
            dispatch(setShowFullScreenOverlay(true));
        }

        if (
            isCurrentBrowserHuman &&
            isUsingDefaultLocation &&
            (pageType === 'ResultsBrowse' ||
                pageType === 'ResultsSearch' ||
                pageType === 'Homepage') &&
            locationPromptVariant === 'C' &&
            !hasBeenShownLocationPrompt(popUpPrompt)
        ) {
            setHasBeenShownLocationPrompt(popUpPrompt);
            dispatch(setShowPopUpPrompt(true));
        }
    }, []); // setting the state in JavaScript so that it doesn't affect crawlers

    return (
        <>
            {showFullScreenOverlay && <FullScreenPrompt />}
            {showPopUpPrompt && <PopUpPrompt />}
        </>
    );
};

export default LocationPrompt;
