import { differenceInDays } from 'date-fns';
import type { PagesState } from '@gumtree/shell/src/load-view';
import type { VipState } from '@gumtree/vip/src/reducers';
import type { SrpState } from '@gumtree/srp/src/reducers';
import type { Conversation } from '@gumtree/shared/src/types/message-centre';
import type { Advert as ManageAdsAdvert } from '@gumtree/manage-my-ads/src/types/advert';

import type { ProfileState } from '@gumtree/profile/src/reducers';
import { FeedbackTag } from '@gumtree/vip/src/types';
import { ShellState } from '@gumtree/shell/src/reducers/common';
import { getL2NameById } from './seo-name-to-id';
import { legacyPageTypeToGAContentGroup } from './ga4-shared';
import getHashFromString from './string-hash';
import { convertSpacesToDashes, convertUnderscoresToDashes } from './string-transform';
import { statusTimestampCheck } from './date-helpers';
import { getPostingLocation } from '../model/search-ads-model';

export function extractInitialGA4DataLayer(
    legacy: GA4.LegacyDataLayer,
    state: PagesState,
    hashedUserEmail?: string
): GA4.PageDeclarationEvent {
    const { u = {}, p = {}, l = {}, a = {}, s = {}, d = {} } = legacy;
    const pageType = p.t;
    const sellerProfile =
        (pageType === 'UserProfile' && detailsFromSellerProfile(state as ProfileState)) ||
        (pageType === 'VIP' && ratingDetailsFromVIP(state as VipState)) ||
        undefined;
    return {
        event: 'pageDeclaration',
        user: u.huid
            ? {
                  loggedInStatus: 'logged in',
                  hashedUserId: u.huid,
                  accountType: u.at === 'Pro' ? 'pro' : 'standard',
                  userId: state.userData?.id,
                  hashedUserEmail,
              }
            : { loggedInStatus: 'logged out' },
        page: {
            legacyType: pageType,
            ...legacyPageTypeToGAContentGroup(pageType),
        },
        experimentPageVariants: u.tg?.ptg,
        experimentSessionVariants: u.tg?.stg || undefined,

        ...(pageType === 'VIP' && listingDetailsFromVIP(legacy, state as VipState)),
        sellerProfile,
        searchBar: getSearchDetails(state as ShellState),
        listListingDetails:
            pageType === 'ResultsSearch' || pageType === 'ResultsBrowse'
                ? listListingDetailsFromSRP(legacy, state as SrpState)
                : undefined,
        searchParameters:
            pageType === 'ResultsSearch' || pageType === 'ResultsBrowse'
                ? searchParametersFromSRP(legacy, state as SrpState)
                : undefined,
        legacy: {
            pageType,
            selectedLocationId: l.c?.id,
            loginProvider: u.lip,
            loggedIn: u.li,
            userAccountType: u.at,
            sessionLevelTestGroup: u.tg?.stg,
            pageLevelTestGroup: u.tg?.stg,
            adPrice: a.prc?.amt,
            sellerAccountType: a.u?.at,
            onsiteSearchResultsPageNumber: s.pn,
            onsiteSearchTotalResults: s.ps,
            onsiteSearchDistance: s.tr,
            postalCode: l.pcid,
            advertClickSource: a.acs,
            bffSRPFlag: true, // we're on BFF
            srpLayout: undefined, // must compute in browser
            userPermanentCookie: d.ck,
            onsiteSearchMinPrice: s.prc?.mn,
            onsiteSearchMaxPrice: s.prc?.mx,
            adSellerType: a.u?.at,
            userRating: a.u?.scr,
            userRatingCount: a.u?.rc,
        },
    };
}

export const extractDogBreed = (adDetails: VipState['adDetails']): string =>
    (Array.isArray(adDetails?.attributes) &&
        convertSpacesToDashes(
            adDetails.attributes.find((attr) => attr.key === 'dog_breed')?.value
        ).toLowerCase()) ||
    '';

export const getSearchDetails = ({
    searchBar: { keyword, location, searchCategoryReducer },
}: ShellState) => {
    return {
        categoryName: keyword.value,
        categoryId: searchCategoryReducer.searchCategory,
        locationName: location.value,
    };
};

export function listingDetailsFromVIP(
    legacy: GA4.LegacyDataLayer,
    { adDetails, sellerContactDetails, video }: VipState
): GA4.BaseListingDetails {
    try {
        const { a: { ic, id, ftr, lpdt, u } = {}, c = {}, l = {} } = legacy;
        const price =
            typeof adDetails.priceInPounds === 'number' ? adDetails.priceInPounds : undefined;
        return {
            listingDetails: {
                age: lpdt ? `${differenceInDays(new Date(), new Date(lpdt))} days` : undefined,
                category: adDetails.categoryName,
                categoryId: adDetails.l3CategoryId,
                contactEmail: Boolean(u?.hue),
                contactNumber: Boolean(sellerContactDetails.replyPhone),
                type: undefined,
                cost: undefined,
                totalCost: undefined,
                id: id!,
                isTrade: Boolean(u?.at),
                location: adDetails.address || undefined,
                locationId: l.c?.id,
                locationHierarchy: computeLocationLevel(legacy),
                locationShowOnMap: Boolean(l.ltlng),
                name: adDetails.title,
                numberOfImages: ic,
                numberWordsDescription: adDetails.description?.reduce(
                    (wordCount, section) => wordCount + (section?.split(/\s+/).length ?? 0),
                    0
                ),
                pricePennies: typeof price === 'number' ? price * 100 : undefined,
                price,
                promotions: undefined,
                subcategory1: c.l1?.id ?? undefined,
                subcategory2: c.l2?.id ?? undefined,
                subcategory3: c.l3?.id ?? undefined,
                subcategory4: c.l4?.id ?? undefined,
                subcategory5: c.l5?.id ?? undefined,
                videoLink: video.urls.length > 0,
                websiteLink: ftr?.includes('WEBSITE_URL') ?? false,
                dogBreed: extractDogBreed(adDetails),
            },
        };
    } catch (e: any) {
        return {
            listingDetails: {},
            listingDetailsError: `${e?.stack ?? e}`,
        };
    }
}

export function joinFeedback(feedbackSummary: FeedbackTag[]) {
    return feedbackSummary.reduce(
        (agg, el) => agg + `${el.title.replace(/ /g, '')}=${el.count};`,
        ''
    );
}

export function ratingDetailsFromVIP({
    sellerRating: { formattedAverage, total },
    sellerStats: { postingSince },
    vipSellerInfo: { lastActiveStatus },
}: VipState): GA4.SellerProfileMeta {
    try {
        return {
            ratingAverage: formattedAverage !== '0.0' ? formattedAverage : undefined,
            ratingCount: total,
            postingFor: postingSince,
            lastActive: lastActiveStatus ? statusTimestampCheck(lastActiveStatus) : undefined,
        };
    } catch (_e) {
        return {
            ratingAverage: undefined,
            ratingCount: undefined,
            postingFor: undefined,
            lastActive: undefined,
        };
    }
}

export function detailsFromSellerProfile({
    userDetails: {
        sellerRating,
        postingSince,
        feedbackSummary,
        totalAdvertCount,
        lastActiveStatus,
    },
    userAdverts: {
        adverts: { adverts },
    },
}: ProfileState): GA4.SellerProfileMeta {
    try {
        return {
            ratingAverage:
                sellerRating?.formattedAverage !== '0.0'
                    ? sellerRating?.formattedAverage
                    : undefined,
            ratingCount: sellerRating?.total,
            postingFor: postingSince,
            lastActive: lastActiveStatus ? statusTimestampCheck(lastActiveStatus) : undefined,
            userLocationShown: Boolean(getPostingLocation(adverts)),
            userTotalItemsListed: totalAdvertCount,
            userFeedbackTags:
                feedbackSummary.length > 0 ? joinFeedback(feedbackSummary) : undefined,
        };
    } catch (_e) {
        return {
            ratingAverage: undefined,
            ratingCount: undefined,
            postingFor: undefined,
            lastActive: undefined,
            userLocationShown: false,
            userTotalItemsListed: 0,
            userFeedbackTags: undefined,
        };
    }
}

export function listListingDetailsFromSRP(
    legacy: GA4.LegacyDataLayer,
    { resultsPage: { searchAds } }: SrpState
): GA4.GA4ListingDetailsItem[] | undefined {
    const { c = {} } = legacy;
    const date = new Date();
    return searchAds.map<GA4.GA4ListingDetailsItem>((ad, position) => ({
        position,
        age: `${differenceInDays(date, new Date(ad.date))} days`,
        contactEmail: undefined,
        contactNumber: undefined,
        type: undefined,
        cost: undefined,
        totalCost: undefined,
        id: ad.id,
        // 🚧 non-pro account can also make trade posting e.g. in cars
        isTrade: ad.proAccount,
        location: ad.location || undefined,
        locationId: undefined,
        locationHierarchy: undefined,
        locationShowOnMap: undefined,
        name: ad.title,
        numberOfImages: ad.numberOfImages,
        numberWordsDescription: ad.shortDescription?.split(/\s+/).length ?? 0,
        price: Number(ad.price?.replace(/[£,]/g, '') || 0),
        pricePennies: Number(ad.price?.replace(/[£,]/g, '') || 0) * 100,
        promotions: [
            ad.featured ? 'FEATURED' : '',
            ad.standout ? 'SPOTLIGHT' : '',
            ad.urgent ? 'URGENT' : '',
        ].filter(Boolean),
        category: getL2NameById(ad.categoryId) ?? ad.categoryId,
        categoryId: ad.categoryId,
        subcategory1: ad.l1CategoryId || undefined,
        subcategory2: ad.l2CategoryId || undefined,
        subcategory3: c.l3?.id || undefined,
        subcategory4: c.l4?.id || undefined,
        subcategory5: c.l5?.id || undefined,
        videoLink: ad.hasVideo,
        websiteLink: undefined,
    }));
}

export function listingDetailsFromMessageCentre(
    activeConversation: Conversation
): GA4.BaseListingDetails {
    try {
        const { advert, adId } = activeConversation;
        const pricePennies = advert.price ?? undefined;
        return {
            listingDetails: {
                age: `${differenceInDays(new Date(), new Date(advert.date))} days`,
                category: advert.categoryName,
                categoryId: advert.subCategoryId,
                contactEmail: undefined,
                contactNumber: undefined,
                cost: undefined,
                id: adId,
                isTrade: undefined,
                location: advert.location,
                locationId: advert.locationId,
                locationHierarchy: undefined,
                locationShowOnMap: undefined,
                name: advert.title,
                numberOfImages: undefined,
                numberWordsDescription: undefined,
                price: typeof pricePennies === 'number' ? pricePennies / 100 : undefined,
                pricePennies,
                promotions: undefined,
                subcategory1: undefined,
                subcategory2: undefined,
                subcategory3: undefined,
                subcategory4: undefined,
                subcategory5: undefined,
                totalCost: undefined,
                type: undefined,
                videoLink: undefined,
                websiteLink: undefined,
            },
        };
    } catch (e: any) {
        console.error(e);
        return {
            listingDetails: {},
            listingDetailsError: `${e?.stack ?? e}`,
        };
    }
}

export function getKeywordCorrectionParams(
    hasQuery: boolean,
    sessionId: string | undefined,
    keywordCorrection: SrpState['resultsPage']['keywordCorrection']
) {
    const correctedKeyword =
        keywordCorrection?.autoCorrectInitial?.correctedKeyword ||
        keywordCorrection?.autoCorrectUndone?.correctedKeyword ||
        keywordCorrection?.autoCorrectRedone?.correctedKeyword;
    const keywordCorrectionId: GA4.GA4SearchParameters['keywordCorrection']['keywordCorrectionId'] =
        hasQuery ? getHashFromString(`${sessionId}${correctedKeyword}`) : undefined;

    let keywordAutoCorrectionState: GA4.GA4SearchParameters['keywordCorrection']['state'] =
        'undefined';
    if (!hasQuery) {
        keywordAutoCorrectionState = 'empty_query';
    } else if (
        keywordCorrection?.autoCorrectInitial?.isActive === true &&
        !keywordCorrection?.autoCorrectInitial?.correctedKeyword
    ) {
        keywordAutoCorrectionState = 'unnecessary';
    } else if (
        keywordCorrection?.autoCorrectInitial?.isActive === true &&
        Boolean(keywordCorrection?.autoCorrectInitial?.correctedKeyword)
    ) {
        keywordAutoCorrectionState = 'corrected';
    } else if (keywordCorrection?.autoCorrectUndone?.correctedKeyword) {
        keywordAutoCorrectionState = 'undone';
    } else if (keywordCorrection?.autoCorrectRedone?.correctedKeyword) {
        keywordAutoCorrectionState = 'redone';
    } else if (keywordCorrection?.autoCorrectInitial?.isActive === false) {
        keywordAutoCorrectionState = 'off';
    }

    return {
        state: keywordAutoCorrectionState,
        ...(keywordCorrectionId &&
        (
            [
                'corrected',
                'undone',
                'redone',
            ] as GA4.GA4SearchParameters['keywordCorrection']['state'][]
        ).includes(keywordAutoCorrectionState)
            ? { keywordCorrectionId }
            : {}),
    };
}

export function searchParametersFromSRP(
    { c = {}, l = {}, s = {}, d }: GA4.LegacyDataLayer,
    {
        resultsPage: {
            searchAds,
            nearbyAds,
            isDominantCategoryActive,
            keywordCorrection,
            alternativeKeywordSuggestions,
            currentFilterStates,
        },
        ...rest
    }: SrpState
): GA4.GA4SearchParameters {
    const hasQuery = Boolean(rest.searchBar.keyword.value);
    const sessionId = d?.s_ck;

    return {
        location: l.c?.id ?? -1,
        locationHierarchy: `L${[l.l0?.id, l.l1?.id, l.l2?.id, l.l3?.id, l.l4?.id].findIndex(
            (x) => x === l.c?.id
        )}`,
        locationRadius: l.d,
        term: s.kw!,
        category: c.c?.id ?? -1,
        subCategory1: c.l2?.id ?? undefined,
        results: searchAds.length,
        nearbyAds: nearbyAds.ads.length,
        priceMin: s.prc?.mn,
        priceMax: s.prc?.mx,
        searchType: s.t === 'Search' ? 'search' : 'browse',
        pageNumber: s.pn!,
        isDominantCategory: isDominantCategoryActive === true,
        keywordCorrection: getKeywordCorrectionParams(hasQuery, sessionId, keywordCorrection),
        alternativeKeywordSuggestions: alternativeKeywordSuggestions.length,
        selectedDogBreed:
            currentFilterStates.dog_breed && typeof currentFilterStates.dog_breed === 'string'
                ? convertUnderscoresToDashes(currentFilterStates.dog_breed)
                : undefined,
    };
}

export function listingDetailsFromManageAds(advert: ManageAdsAdvert): GA4.BaseClickListingDetails {
    const adAge = advert.age.toLowerCase().replace('ago', '').trim();
    const promotions = [
        `urgent=${advert.advertFeatures?.urgent}, featured=${advert.advertFeatures?.featured}`,
    ];
    const isTrade = advert.isProUser || advert.sellerType === 'trade';

    try {
        return {
            clickListingDetails: {
                age: adAge,
                category: getL2NameById(advert.l3CategoryId !== 0 ? advert.l3CategoryId : advert.l2CategoryId) ?? advert.l1CategoryId,
                categoryId: advert.l3CategoryId !== 0 ? advert.l3CategoryId : advert.l2CategoryId,
                contactEmail: undefined,
                contactNumber: undefined,
                cost: undefined,
                id: Number(advert.adId),
                isTrade,
                location: advert.displayLocationText,
                locationHierarchy: undefined,
                locationId: undefined,
                locationShowOnMap: undefined,
                name: advert.title,
                numberOfImages: advert.images.numberOfImages,
                numberWordsDescription: undefined,
                price: parseFloat(advert.displayPrice.replace(/[£,]/g, '')),
                pricePennies: parseFloat(advert.displayPrice.replace(/[£,]/g, '')) * 100,
                promotions,
                subcategory1: advert.l1CategoryId,
                subcategory2: advert.l2CategoryId,
                subcategory3: advert.l3CategoryId,
                subcategory4: undefined,
                subcategory5: undefined,
                totalCost: undefined,
                type: undefined,
                videoLink: undefined,
                websiteLink: undefined,
            },
        };
    } catch (e: any) {
        console.error(e);
        return {
            clickListingDetails: {},
            clickListingDetailsError: `${e?.stack ?? e}`,
        };
    }
}

/**
 * For extracting labels.
 * @author https://github.com/sunknudsen/react-node-to-string/blob/master/src/index.ts
 */
export function reactNodeToString(
    reactNode: React.ReactNode,
    /** Pass `React.isValidElement`. */
    isValidElement: <P>(object: {} | null | undefined) => object is React.ReactElement<P>
) {
    let string = '';
    if (typeof reactNode === 'string') {
        string = reactNode;
    } else if (typeof reactNode === 'number') {
        string = reactNode.toString();
    } else if (reactNode instanceof Array) {
        reactNode.forEach(function (child) {
            string += reactNodeToString(child, isValidElement);
        });
    } else if (isValidElement(reactNode)) {
        string += reactNodeToString((reactNode.props as any).children, isValidElement);
    }
    return string;
}

/**
 * Supports relative urls.
 */
export function getLinkHostname(link: string) {
    try {
        return new URL(link, window.location.href).hostname;
    } catch {
        return window.location.hostname;
    }
}

function computeLocationLevel({ l = {} }: GA4.LegacyDataLayer) {
    const locationId = l.c?.id;
    if (locationId !== undefined) {
        return `L${
            4 - [l.l4, l.l3, l.l2, l.l1, l.l0].findIndex((loc) => loc?.id === locationId)
        }` as const;
    } else {
        return undefined;
    }
}
