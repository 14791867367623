import MetricsRegistry from '../metrics-registry';

class AppWideMetrics extends MetricsRegistry {
    registerPageViewEvent(pageType, appViewDevice) {
        this.registerServerSideEvent('pageview', {
            pageType,
            appViewDevice,
        });
    }

    registerConsentBannerInteractionEvent(interactionType) {
        this.registerClientSideEvent('consent_banner_interaction', {
            interactionType,
        });
    }

    registerPageViewTiming(pageType, timeTaken, appViewDevice) {
        if (timeTaken) {
            const timeTakenInSeconds = timeTaken / 1000;

            this.registerServerSideHistogramEvent(`pageview_duration_seconds`, timeTakenInSeconds, {
                pageType,
                appViewDevice,
            });
        }
    }

    getModuleName() {
        return 'app';
    }

    getCountersToRegister() {
        return [
            { name: 'pageview', labelNames: ['pageType', 'appViewDevice'] },
            { name: 'consent_banner_interaction', labelNames: ['interactionType'] },
        ];
    }

    getHistogramsToRegister() {
        return [
            {
                name: 'pageview_duration_seconds',
                labelNames: ['pageType', 'appViewDevice'],
                buckets: [0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.8, 1.2, 2.4, 5, 10, 20, 30, 60],
            },
        ];
    }
}

export default new AppWideMetrics();
