import { DataLayerBase } from '../data-layer-service';

const convertDateToISOFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString();
};

export const parseDatalayer = (dataLayerBase: DataLayerBase) => {
    return {
        pageType: dataLayerBase?.p?.t,
        platform: dataLayerBase?.p?.pl,
        rootCategoryId: dataLayerBase?.c?.l0?.id,
        l1CategoryId: dataLayerBase?.c?.l1?.id,
        l2CategoryId: dataLayerBase?.c?.l2?.id,
        l3CategoryId: dataLayerBase?.c?.l3?.id,
        l4CategoryId: dataLayerBase?.c?.l4?.id,
        rootLocationId: dataLayerBase?.l?.l0?.id,
        l1LocationId: dataLayerBase?.l?.l1?.id,
        l2LocationId: dataLayerBase?.l?.l2?.id,
        l3LocationId: dataLayerBase?.l?.l3?.id,
        l4LocationId: dataLayerBase?.l?.l4?.id,
        vehicleMake: dataLayerBase?.a?.attr?.vehicle_make,
        vehicleModel: dataLayerBase?.a?.attr?.vehicle_model,
        transmission: dataLayerBase?.a?.attr?.vehicle_transimission,
        fuelType: dataLayerBase?.a?.attr?.vehicle_fuel_type,
        adId: dataLayerBase?.a?.id,
        userType: dataLayerBase?.a?.u?.at,
        sellerType: dataLayerBase?.a?.attr?.seller_type,
        priceAmount: dataLayerBase?.a?.prc?.amt,
        priceCurrency: dataLayerBase?.a?.prc?.cur,
        descriptionLength: dataLayerBase?.a?.dl,
        imageCount: dataLayerBase?.a?.ic,
        creationDate: dataLayerBase?.a?.cdt && convertDateToISOFormat(dataLayerBase.a.cdt),
        lastPublishedDate: dataLayerBase?.a?.lpdt && convertDateToISOFormat(dataLayerBase.a.lpdt),
        advertClickSource: dataLayerBase?.a?.acs,
    };
};
