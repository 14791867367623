import { AdSlot, DfpServerConfig, GoogleTag } from '@gumtree/shared/src/types/advertising/dfp';
import { Device, L1Category, L2Category, PageType } from '@gumtree/shared/src/types/client-data';
import { WindowWithAdvertising, WindowWithGoogleTag } from '@gumtree/shared/src/types/window';
import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';

import { getConsentData } from '../gdpr/util';

import getDfpSlotsConfig from './ad-config';
import DisplayAdSlot from './display-ad-slot';
import addEventListenerForHouseRefresh from './house-refresh-event-listener';
import { initialiseTcfACString } from '../gdpr/onetrust-add-additional-consent-string';
import { PageConfig } from './ad-config/build-page-config';
import type { SlotName } from './ad-config/slot-configs';

type Callback = () => void;

const prioritizedDfpOnlySlotIds: SlotName[] = ['premiumNative1', 'premiumNative2', 'leaderboard'];

const skippingInitialLoadSlotIds: SlotName[] = ['galleryBanner', 'galleryMPU'];
let isInitialisationComplete = false;
const queuedCommands: Callback[] = [];

const isConfigValid = (googletag: GoogleTag, config: DfpServerConfig, slots: object) => {
    return Boolean(googletag && config && slots && Object.keys(slots).length);
};

const configurePageOptions = (
    googletag: GoogleTag,
    config: DfpServerConfig,
    hashedUserIdFromDataLayer: string | undefined
) => {
    googletag.cmd.push(() => {
        const ppid = config.ppid || hashedUserIdFromDataLayer;

        if (config.targeting) {
            Object.entries(config.targeting).forEach(([key, value]) => {
                key !== undefined && (key = key.toString());
                value !== undefined && (value = value.toString());
                googletag.pubads().setTargeting(key, value as string);
            });
        }

        if (ppid && 0 !== ppid.length) {
            googletag.pubads().setPublisherProvidedId(ppid);
        }
        googletag.pubads().disableInitialLoad();
        googletag.pubads().setCentering(true);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
    });
};

export const loadAds = (slotInstances: DisplayAdSlot[], googletag: GoogleTag) => {
    const command = async () => {
        const { coarseConsent } = await getConsentData(window);

        if (coarseConsent) {
            googletag.pubads().setTargeting('adconsent', coarseConsent);
        }

        googletag.pubads().refresh(slotInstances.map(({ adSlot }) => adSlot) as AdSlot[]);
    };
    if (isInitialisationComplete) {
        googletag.cmd.push(command);
    } else {
        queuedCommands.push(command);
    }
};

const refreshAds =
    (
        slotInstances: DisplayAdSlot[],
        googletag: GoogleTag,
        pageType: PageType,
        deviceType: Device,
        l1Category: L1Category,
        w: WindowWithGoogleTag & WindowWithAdvertising
    ) =>
    () => {
        if (w.advertising?.display?.isHouseRefreshOff !== true) {
            loadAds(
                slotInstances.filter((i) => i.isRefreshable(deviceType, l1Category, pageType, w)),
                googletag
            );
        }
    };

const initDisplayAds = async (
    googletag: GoogleTag,
    window: WindowWithGoogleTag & WindowWithAdvertising,
    config: DfpServerConfig | undefined,
    resultSize = 0,
    l1Category: L1Category,
    l2Category: L2Category,
    pageType: PageType,
    device: Device,
    hashedUserIdFromDataLayer: string | undefined
) => {
    if (!config) {
        return;
    }

    const slots: PageConfig = getDfpSlotsConfig({
        pageType,
        resultSize,
        device,
        l1Category: l1Category || '',
        l2Category,
    });

    if (isConfigValid(googletag, config, slots)) {
        const slotsToFetch = slots.filter((config) =>
            Boolean(window.document.getElementById(config.slotId))
        );

        slotsToFetch.forEach((slot) => {
            // some ad placeholders are "display: none" initially and needs to be uncovered
            // except those marked startWithCollapsedSlot
            const el = window.document.getElementById(slot.slotId);
            const currentScreenTShirtSize = getCurrentBreakpoint(window)?.tshirt;
            const isPlaceholderStartWithCollapsedSlot =
                slot.startWithCollapsedSlot &&
                slot.startWithCollapsedSlot.find(
                    (_) =>
                        _.pageType === pageType &&
                        currentScreenTShirtSize &&
                        _.screenSizes.includes(currentScreenTShirtSize)
                );

            if (el && !isPlaceholderStartWithCollapsedSlot) {
                el.style.removeProperty('display');
            }
        });

        const slotInstances: DisplayAdSlot[] = slotsToFetch.map(
            (slotConfig) => new DisplayAdSlot(pageType, slotConfig, googletag, window)
        );

        addEventListenerForHouseRefresh(window);

        // event listeners must be defined before the events trigger in Prebid
        window.document.addEventListener('GUM.ADS.DISPLAY.LOAD', () => {
            loadAds(
                slotInstances.filter(
                    (instance) =>
                        !instance.isSlotIdInList(prioritizedDfpOnlySlotIds) &&
                        !instance.isSlotIdInList(skippingInitialLoadSlotIds)
                ),
                googletag
            );
        });

        window.document.addEventListener(
            'GUM.ADS.DISPLAY.REFRESH',
            refreshAds(slotInstances, googletag, pageType, device, l1Category, window)
        );

        configurePageOptions(googletag, config, hashedUserIdFromDataLayer);

        slotInstances.forEach((slot) => slot.callGoogleDisplay(window));

        await initialiseTcfACString(window);

        isInitialisationComplete = true;

        queuedCommands.forEach((command) => {
            googletag.cmd.push(command);
        });

        // load prioritised slots
        loadAds(
            slotInstances.filter(
                (instance) =>
                    instance.isSlotIdInList(prioritizedDfpOnlySlotIds) &&
                    !instance.isSlotIdInList(skippingInitialLoadSlotIds)
            ),
            googletag
        );
    }
};

export default initDisplayAds;
